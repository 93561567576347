import React, { useEffect, useState } from 'react'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import i18n from './UserMenu.i18n'
import { Box, Divider, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Organization } from '@/app/model'
import { AddressApiService, OrganizationApiService, switchedOrganizationRequests, UserApiService } from '@/app/service/ApiService'
import { User } from '@/app/model/User'
import { accountProfileRoute, invoiceListRoutes, newOrderListRoute, signOutRoute, organizationRoute } from '@/app/ui/routes'
import { getUserRoleName } from '@/app/ui/lib/helpers'
import { setUser as reduxSetUser } from '@/app/ui/redux/actions/UserActions'
import { setUserOrganization as reduxSetUserOrganization } from '@/app/ui/redux/actions/UserOrganizationActions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import {
    Avatar,
    BoldText,
    Grid,
    Link,
    LongText,
    Menu,
    OrganizationName,
    OrganizationSelectBox,
    OrganizationSelectMenu,
    OrganizationSelectMenuBackgroundOverlay,
    OrganizationSelectMenuItem,
    SignOutIcon,
    SignOutText,
} from './UserMenu.styled'
import {
    setDefaultBillingAddresses as reduxSetDefaultBillingAddresses,
    setDefaultShippingAddresses as reduxSetDefaultShippingAddresses,
} from '@/app/ui/redux/actions/AddressListActions'
const UserMenu: React.FunctionComponent<UserMenuProps> = ({ user, userOrganization }) => {
    const { t } = useI18n(i18n)
    const history = useHistory()
    const dispatch = useDispatch()

    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)
    const [ isOrganizationSelectMenuOpen, setIsOrganizationSelectMenuOpen ] = useState<boolean>(false)
    const [ userOrganizations, setUserOrganizations ] = useState<Organization[] | []>([])

    useEffect(() => {
        if (user) {
            OrganizationApiService.userOrganizationsByUserId(user.id)
                .then((result) => {
                    setUserOrganizations(result.organizations)
                })
                .catch((error) => console.error(error))
        }
    }, [ user ])

    const toggleMenu = (): void => {
        setIsMenuOpen(!isMenuOpen)
    }

    const closeMenu = (): void => {
        setIsMenuOpen(false)
    }

    const getCurrentUser = async (): Promise<void> => {

        const [ fetchedBillingAddresses, userWithOrganizationPermissions, fetchedDefaultShippingAddresses, fetchedDefaultBillingAddresses ] = await Promise.all([
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                },
            ),
            await UserApiService.getCurrent(),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isShippingDefault: true,
                    isShipping: true,
                },
            ),
            await AddressApiService.list(
                {
                    page: 1,
                    resultPerPage: 100,
                },
                {
                    isBilling: true,
                    isBillingDefault: true,
                },
            ),
        ])
        if (fetchedDefaultBillingAddresses) {
            dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
        }
        if (fetchedDefaultShippingAddresses) {
            dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
        }
        if (userWithOrganizationPermissions && fetchedBillingAddresses) {
            userWithOrganizationPermissions.seBillToAddresses(fetchedBillingAddresses.addresses.map((address) => ({
                 id: address.id,
                 organizationId: userWithOrganizationPermissions.organizationId,
                 userId: userWithOrganizationPermissions.id,
                 billTo: address,
            })))
            dispatch(reduxSetUser(userWithOrganizationPermissions))
        }
    }

    const impersonatingInfo = user?.getImpersonatingInfo()
    const showImpersonationInfo = !!impersonatingInfo && !!userOrganization && (userOrganization.isSystem() || impersonatingInfo.active)

    return <>
        <SecondaryButton
            aria-owns={isMenuOpen ? 'menu-appbar' : ''}
            aria-haspopup='true'
            startIcon={
                user?.pictureUrl
                    ? <Avatar
                        alt={user ? (user?.shortFullName || user?.email) : t('Guest')}
                        src={user.pictureUrl}
                        variant='square'
                    />
                    : <FontAwesomeIcon icon={[ 'far', 'user' ]}/>
            }
            size={'small'}
            onClick={toggleMenu}
        />
        <Menu
            id='menu-appbar'
            anchorReference='none'
            open={isMenuOpen}
            onClose={closeMenu}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <Grid container>
                <Grid item xs={12} p={4} pb={1}>
                    <LongText variant='h6'>
                        {user ? user.shortFullName : t('Guest')}
                    </LongText>
                </Grid>
                <Grid item xs={12} p={4} pt={1} pb={2}>
                    <LongText variant='body2'>
                        {user?.email}
                    </LongText>
                </Grid>
                <Grid item xs={12} pt={2} pb={3}>
                    <Divider light />
                </Grid>
                {
                    impersonatingInfo?.active
                    &&
                    <Grid item container xs={12} justifyContent="space-between">
                        <Grid item xs="auto" p={4} pt={1} pb={2}>
                            <Typography variant="body2" display="inline">
                                {t('Imp:')}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto" p={4} pt={1} pb={2}>
                            <OrganizationName variant="body2">
                                {impersonatingInfo?.organizationName}
                            </OrganizationName>
                        </Grid>
                    </Grid>
                }
                <Grid item container xs={12} justifyContent='space-between'>
                    <Grid item xs='auto' p={4} pt={1} pb={2}>
                        <Typography variant='body2' display='inline'>
                            {t('Org:')}
                        </Typography>
                    </Grid>
                    <Grid item xs='auto' p={4} pt={1} pb={2}>
                        {
                            !user
                                ? <OrganizationName variant='body2'>
                                    {t('Guest Organization')}
                                </OrganizationName>
                                : (
                                    (!impersonatingInfo?.active && userOrganization && userOrganizations.length > 1)
                                        ? <>
                                            <OrganizationSelectBox
                                                onClick={(): void => setIsOrganizationSelectMenuOpen(true)}
                                            >
                                                <Box mr={2}>
                                                    <OrganizationName variant='body2'>
                                                        {userOrganization?.name}
                                                    </OrganizationName>
                                                </Box>
                                                <FontAwesomeIcon icon={[ 'fas', 'caret-down' ]} />
                                            </OrganizationSelectBox>
                                            <OrganizationSelectMenuBackgroundOverlay
                                                display={isOrganizationSelectMenuOpen ? 'block' : 'none'}
                                                onClick={(): void => setIsOrganizationSelectMenuOpen(false)}
                                            >
                                                <OrganizationSelectMenu
                                                    open={isOrganizationSelectMenuOpen}
                                                    onClick={(): void => setIsOrganizationSelectMenuOpen(false)}
                                                >
                                                    {userOrganizations.map((org) =>
                                                        <OrganizationSelectMenuItem
                                                            key={org.name}
                                                            value={org.id}
                                                            onClick={() => {
                                                                void (async (): Promise<void> => {
                                                                    if (org) {
                                                                        switchedOrganizationRequests(org.id)
                                                                        await getCurrentUser()
                                                                        dispatch(reduxSetUserOrganization(org))
                                                                    }
                                                                })()
                                                            }}>
                                                            <Typography variant='inherit' noWrap>
                                                                {org.name}
                                                            </Typography>
                                                        </OrganizationSelectMenuItem>,
                                                    )}
                                                </OrganizationSelectMenu>
                                            </OrganizationSelectMenuBackgroundOverlay>
                                        </>
                                        : <OrganizationName variant='body2'>
                                            {userOrganization?.name}
                                        </OrganizationName>
                                )
                        }
                    </Grid>
                </Grid>
                <Grid item container xs={12} justifyContent='space-between'>
                    <Grid item xs='auto' p={4} pt={1} pb={2}>
                        <Typography variant='body2' display='inline'>
                            {t('Role:')}
                        </Typography>
                    </Grid>
                    <Grid item xs='auto' p={4} pt={1} pb={2}>
                        <BoldText variant='body2' display='inline'>
                            {userOrganization ? t((
                                user ? getUserRoleName(user, userOrganization) : 'User'
                            ) as 'Admin' | 'Agent' | 'Shipper' | 'Accountant' | 'Supplier' | 'User') : ''}
                        </BoldText>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={2} pb={2}>
                    <Divider light />
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <BoldText variant='body2' display='inline'>
                        {t('My Account')}
                    </BoldText>
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <Typography variant='body2' display='inline'>
                        <Link to={newOrderListRoute.path}>
                            {t('Orders')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <Typography variant='body2' display='inline'>
                        <Link to={invoiceListRoutes.path}>
                            {t('Invoices')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <Typography variant='body2' display='inline'>
                        <Link to={accountProfileRoute.path}>
                            {t('Profile')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} pt={2} pb={2}>
                    <Divider light />
                </Grid>
                {showImpersonationInfo &&
                    <React.Fragment>
                        <Grid item xs={12} p={4} pt={2} pb={2}>
                    <BoldText variant='body2' display='inline'>
                        {t('My Organization')}
                    </BoldText>
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <Typography variant='body2' display='inline'>
                        <Link to={`${organizationRoute.path}?tab=user`}>
                            {t('Users')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <Typography variant='body2' display='inline'>
                        <Link to={`${organizationRoute.path}?tab=settings`}>
                            {t('Settings')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} p={4} pt={2} pb={2}>
                    <Typography variant='body2' display='inline'>
                        <Link to={`${organizationRoute.path}?tab=permissions`}>
                            {t('Permissions')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} pt={2} pb={2}>
                    <Divider light />
                </Grid>
                    </React.Fragment>
                }
                <Grid item container xs={12} p={4} pt={2} alignItems='center'>
                    <Grid item xs={12}>
                        <Link to={signOutRoute.path}>
                            <SignOutIcon icon={[ 'far', 'sign-out' ]}/>
                            <SignOutText variant='body2' display='inline'>
                                {t('Sign Out')}
                            </SignOutText>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Menu>
    </>
}

export { UserMenu }

export interface UserMenuProps {
    user: User | null
    userOrganization: Organization | null
}