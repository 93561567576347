import React, { useState } from 'react'
import View from './View'
import useCountries from '../../hooks/useCountries'
import { useSelector } from 'react-redux'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors/UserSelectors'
import { Country as countryPhone } from '@lazr/openapi-client'
import { type CountryCode as PhoneCountryCode } from 'libphonenumber-js'
import { ApiServiceAction } from '@lazr/enums'
import { useParams } from 'react-router-dom'
import useCreditApplication from '../hooks/useCreditApplication'
import { processData } from './processData'


const Add: React.FunctionComponent = () => {
    const user = useSelector(reduxGetUser)
    const { id: organizationId } = useParams<{ id: string }>()
    const userCountry = (user?.locale.country ?? countryPhone.CA) as PhoneCountryCode

    const countries = useCountries({ wait: 0.1 }, ApiServiceAction.LIST).list
    const [ returnProcessData, setReturnProcessData] = useState<any>()

    const newCreditApplication = useCreditApplication(
        {
            newData: returnProcessData,
        },
        ApiServiceAction.NEW,
    ).new

    return (
        <View
            nameID='Address'
            defaultCreditApplication={{
                nameID: 'DefaultCreditApplication',
                processData: { fn: processData, setReturnProcessData: setReturnProcessData },
                countries: countries,
                creditApplicationData: newCreditApplication,
                organizationId: organizationId,
            }}
        />
    )
}

export default React.memo(Add, () => true)
