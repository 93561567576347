/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['System Dashboard']: `System Dashboard`,
    ['Orders']: `Orders`,
    ['Marketplace']: `Marketplace`,
    ['Invoices']: `Invoices`,
    ['Invoice CSV Batches']: `Invoice CSV Batches`,
    ['Tracking Updates CSV Batches']: `Tracking Updates CSV Batches`,
    ['Address Book']: `Address Book`,
    ['Organizations']: `Organizations`,
    ['My Organization']: `My Organization`,
    ['EDI']: `EDI`,
    ['Go to GMX']: 'Go to GMX',
    ['Coming soon!']: `Coming soon!`,
    'Schedule Pickup':'Schedule Pickup',
    'Agreement': 'Agreement',
}
