import { logger } from '../../logger'
import { ApiError, ErrorResponse, UuidV4, Country, CreditApplicationService } from '@lazr/openapi-client'
import { handleUnauthorizedException } from './index'
import { CreditApplicationAttributes } from '../../model/CreditApplication'
import { isAddressComplete } from '../../ui/lib/helpers'

export const CreditApplicationApiService = Object.freeze({
    submit: async (creditApplicationAttributes: CreditApplicationAttributes): Promise<UuidV4| null> => {
        // if (!isAddressComplete(creditApplicationAttributes)) {
        //     throw new Error('Missing some address attributes')
        // }

        let submitCreditApplicationResponse
        try {
            submitCreditApplicationResponse = await CreditApplicationService.submitApplication({
                organizationId: creditApplicationAttributes.organizationId,
                name: creditApplicationAttributes.name,
                emails: creditApplicationAttributes.emails,
                phone: creditApplicationAttributes.phone,
                streetAddress1: creditApplicationAttributes.streetAddress,
                streetAddress2:  creditApplicationAttributes.streetAddress2,
                streetAddress3:  creditApplicationAttributes.streetAddress3,
                city: creditApplicationAttributes.city,
                state: creditApplicationAttributes.state,
                country: creditApplicationAttributes.country ?? Country.CA,
                postalCode: creditApplicationAttributes.postalCode,
            })
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('submit', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to submit credit application')
            }
            throw new Error('Unable to submit credit application')
        }

        if (!submitCreditApplicationResponse.data) {
            logger.debug('add', JSON.stringify(submitCreditApplicationResponse, null, 4))
            throw new Error('Unable to submit credit application')
        }

        return submitCreditApplicationResponse.data.id
    },
})
