import { SearchAddressType } from "@/app/ui-new/components/AddressSearchBar/AddressSearchBar"
import { AddressFormProps } from "@/app/ui-new/components/Form/FullComponents/AddressForm/AddressForm"


export const processAddress = (data:any) => {
    const dataForm: AddressFormProps['address'] = {
        contactName: { label:'Legal Name', value: data?.contactName },
        contactEmails: { required: false, value: data?.contactEmails },
        contactPhone: { required: false, value: data?.contactPhone },
        contactPhoneExtension: {  required: false, value: data?.contactPhoneExtension },
        addressType: { required: false, value: data?.addressType, hide:true },
        companyName: { hide: true, required: false, value: data?.companyName},
        street: { value: data?.street ?? data?.streetAddress },
        street3: { value: data?.street3 ?? data?.streetAddress3 },
        street2: {  required: false, value: data?.street2 ?? data?.streetAddress2 },
        city: { value: data?.city },
        country: { value: data?.country },
        state: { value: data?.state },
        postalCode: { value: data?.postalCode} ,
    }

    const addressArr = [data?.streetAddress2, data?.streetAddress1, data?.streetAddress, data?.city, data?.state, data?.country]

    dataForm.googleAddress = {
        description: `${addressArr.filter((item) => !!item).join(', ')} ${data?.postalCode ?? ''}`,
        isAddressBook: false,
        type: SearchAddressType.ADDRESS,
        mainText: `${addressArr.filter((item) => !!item).join(', ')} ${data?.postalCode ?? ''}`,
    }

    return dataForm
}
