// newProcessData
// deleteProcessData
// editProcessData

import {type ApiServiceAction } from '@lazr/enums'
import { Country } from '@/app/model'
import { CreditApplicationAttributes } from '../../../../model/CreditApplication'

export interface ProcessDataProps {
    fn: (
        data: any,
        countryList: Country[],
        setReturnProcessData: React.Dispatch<React.SetStateAction<CreditApplicationAttributes>>,
        action: ApiServiceAction,
    ) => void
    setReturnProcessData: any
}

export const processData = (
    data: any,
    countryList: Country[],
    setReturnProcessData: React.Dispatch<React.SetStateAction<CreditApplicationAttributes>>,
    action: ApiServiceAction,
) => {
    if (action === 'NEW') {
        newProcessData(data, countryList, setReturnProcessData)
    }
}

const newProcessData = (
    data: any,
    countryList: Country[],
    setReturnProcessData: React.Dispatch<React.SetStateAction<CreditApplicationAttributes>>,
) => {
    const addressValue = data?.addressData

    const pickupCodeCountry = countryList.find((item) => item.name === addressValue?.country?.value)

    const finalData: CreditApplicationAttributes = {
        organizationId: data?.organizationId,
        postalCode: addressValue?.postalCode?.value ?? '',
        country: pickupCodeCountry?.code as any,
        state: addressValue?.state?.value ?? '',
        city: addressValue?.city?.value ?? '',
        streetAddress3: addressValue?.street3?.value ?? null,
        streetAddress2: addressValue?.street2?.value ?? null,
        streetAddress: addressValue?.street?.value ?? '',
        phone: addressValue?.contactPhone?.value ?? null,
        emails: addressValue?.contactEmails && addressValue?.contactEmails?.value ? (Object.values(addressValue?.contactEmails?.value) as string[]) : [],
        name: addressValue?.contactName?.value ?? '',
    }

    setReturnProcessData(finalData)
}
