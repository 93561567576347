import React, { useState } from 'react'
import type { AddressFormProps } from '@/app/ui-new/components/Form/FullComponents/AddressForm/AddressForm'
import { Box, Grid } from '@material-ui/core'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './CreditApplication.i18n'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import DoubleAddressForm from '@/app/ui-new/components/Form/FullComponents/DoubleAddressForm/DoubleAddressForm'
import { OnError } from '@/app/ui-new/components/Form/FormSimple'
import { processAddress } from '../../helpers'
import { ProcessDataProps } from '../../New/processData'
import { ReturnCreditApplicationDataProps } from '../../hooks/useCreditApplication'
import { ApiServiceAction } from '@lazr/enums'
import { UuidV4 } from '@lazr/types'
import type { Country as CountryList } from '@/app/model'
import { useHistory } from 'react-router'
import {
    Dialog,
    DialogTitle,
    DialogText,
    DialogButtonContainer
} from '@/app/ui-new/components/Dialog/Dialog'


export interface CreditApplicationProps {
    nameID: string
    defaultCreditApplication: CreditApplicationDefaultProps
}

export interface CreditApplicationDefaultProps {
    nameID: string
    processData: {
        fn: ProcessDataProps['fn']
        setReturnProcessData: ProcessDataProps['setReturnProcessData']
    }
    countries?: CountryList[]
    organizationId?: UuidV4
    creditApplicationData?: ReturnCreditApplicationDataProps
}


const CreditApplication: React.FunctionComponent<CreditApplicationProps> = ({ nameID, defaultCreditApplication }) => {
    const { t } = useI18n(i18n)
    const history = useHistory()

    const initialIsValid: OnError = {
    data: {},
    isValid: false,
    isDirty: false,
    }

    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [ organizationId, setOrganizationId] = useState<UuidV4 | undefined>(defaultCreditApplication.organizationId)
    const [ address, setAddress ] = useState<AddressFormProps['address'] | undefined>()
    const [ isValidAddress, setIsValidAddress] = useState<OnError>(initialIsValid)


    const getAddressData = ({ address1 }: { address1: AddressFormProps['address'] }) => {
        setAddress(address1)
        const isValid = isValidAddress
        isValid.isValid = true
        isValid.isDirty = true
        setIsValidAddress(isValid)
    }
    const getAddressDataError = (data: OnError) => {
        setIsValidAddress(data)
    }
    const handleSubmitApplication = () => {
        setOpenDialog(true)
        const data = {
            organizationId,
            addressData: address,
        }
        if (defaultCreditApplication.countries)
            defaultCreditApplication.processData.fn(
                data,
                defaultCreditApplication.countries,
                defaultCreditApplication.processData.setReturnProcessData,
                ApiServiceAction.NEW,
            )

    }
    return (
        <>
            <Grid container>
                <Grid item container spacing={8}>
                    <Grid item xs={12}>
                            <DoubleAddressForm
                                countries={defaultCreditApplication.countries}
                                title='Credit Application'
                                address1={{
                                    data: processAddress(address),
                                    title: 'Address',
                                }}

                                hasAddressSameAddress2={{ isSame: true, show: false }}
                                getData={getAddressData}
                                getError={getAddressDataError} />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Box p={2}>
                                <PrimaryButton
                                    onClick={handleSubmitApplication}
                                    id={'submitApplication'}
                                    disabled = {
                                        openDialog ||
                                        !isValidAddress.isDirty
                                            ? true
                                            : !isValidAddress.isValid
                                    }
                                >
                                    {t('Submit')}

                                </PrimaryButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {defaultCreditApplication?.creditApplicationData && (<Dialog
                size='small'
                open={openDialog}
            >
                <DialogTitle>
                    {
                        defaultCreditApplication.creditApplicationData?.error ?
                            t('Credit Application Failed') : t('Credit Application Submitted')
                    }
                </DialogTitle>
                <DialogText mt={4} mb={2}>
                    {
                        /* eslint-disable-next-line max-len */
                        defaultCreditApplication.creditApplicationData?.error ?
                            t('There is an error occurred in the process, please contact support for solution.')
                            :
                            t('Congrats! You have successfully submitted your credit application.')
                    }
                </DialogText>
                <DialogButtonContainer centered>
                    <PrimaryButton
                        size='medium'
                        onClick={() => {
                            if (defaultCreditApplication.creditApplicationData?.error) {
                                setOpenDialog(false)
                            } else {
                                history.goBack()
                            }
                        }}
                    >
                        {t('Close')}
                    </PrimaryButton>
                </DialogButtonContainer>
            </Dialog>)}
        </>
    )
}

export default CreditApplication
