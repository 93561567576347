import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

const appRoot = document.getElementById('app')

if (appRoot !== null) {
    appRoot.classList.add('notranslate')
    ReactDOM.render(
        // Recommended to keep <React.StrictMode> mode disabled for now when using material ui library.
        // Reference: https://github.com/mui-org/material-ui/issues/13394
        // Reference: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
        //<React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
        //</React.StrictMode>,
        appRoot,
    )
}
