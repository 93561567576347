/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SubmitCreditApplicationRequest } from '../models/SubmitCreditApplicationRequest';
import type { SuccessResponse_SubmitCreditApplicationResponse_ } from '../models/SuccessResponse_SubmitCreditApplicationResponse_';
import { request as __request } from '../core/request';

export class CreditApplicationService {

    /**
     * Submit Credit Application
     * @param requestBody The data
     * @returns SuccessResponse_SubmitCreditApplicationResponse_ OK
     * @throws ApiError
     */
    public static async submitApplication(
requestBody: SubmitCreditApplicationRequest,
): Promise<SuccessResponse_SubmitCreditApplicationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/submit-application`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}