import type { UserUiSettings } from '@/app/model/User'
import { capitalizeFirstLetter } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'
import { CommercialInvoiceAndDocShipment, UserUiType } from '@lazr/enums'
import { TransportType } from '@lazr/openapi-client'
import { deepCopy } from '@lazr/utilities'

export const capitalizeEachWord = (input?: string): string => {
    return input
        ? input
              .split(' ')
              .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
              .join(' ')
        : ''
}

export const transformAllLowercaseAndCapitalizeFirstLetter = (text: any) => capitalizeFirstLetter(text.toString().toLowerCase())

export const updateTableSettings = async ({
    name,
    val,
    setUiSettings,
    uiSettings,
    UserUiType,
    updateKey,
}: {
    name: string
    val: string
    setUiSettings: React.Dispatch<React.SetStateAction<UserUiSettings | undefined | null>>
    uiSettings: UserUiSettings | null | undefined
    UserUiType: string,
    updateKey: keyof UserUiSettings
}) => {
    const uiSettingsData: UserUiSettings['tableSettings'] = uiSettings?.[updateKey] || deepCopy(uiSettings?.[updateKey])
    const foundIndex = uiSettingsData ? uiSettingsData.findIndex((setting) => setting.name === name && setting.type === UserUiType) : -1

    if (foundIndex === -1) {
        setUiSettings((State) => {
            const prevState: UserUiSettings = deepCopy(State)
            return {
                ...prevState,
                [updateKey]: [
                    ...(prevState?.[updateKey] || []),
                    {
                        name: name,
                        type: UserUiType,
                        value: val,
                    },
                ],
            }
        })
    } else if (foundIndex >= 0) {
        // If the setting exists, update its value
        setUiSettings((State) => {
            const prevState: UserUiSettings = deepCopy(State)
            if (prevState?.[updateKey]?.[foundIndex]) {
                prevState![updateKey]![foundIndex].value = val || ''
            }
            return prevState
        })
    }
}

export const titleCaseText = (text: string): string => {
    // Split the text into an array of words
    if (!text) {
        return 'n/a'
    }
    const words: string[] = text.split(' ')

    // Capitalize the first letter of each word
    const titleCaseWords: string[] = words.map((word: string) => {
        // Ensure the word is not empty
        if (word.length > 0) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }
        return word // Preserve empty words
    })

    // Join the words back into a string
    const titleCaseText: string = titleCaseWords.join(' ')

    return titleCaseText
}

export const firstUppercaseRestLowercase = (text: string) => {
    if (!text) {
        return ''
    }
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export interface OrderByKey {
    item: any
    order: number
}
export const sortByOrderKey = (array: OrderByKey[]) => {
    // Use the Array.sort() method to sort the array by the 'order' key
    array.sort((a, b) => a.order - b.order)
    return array
}

export const reorderObject = (obj: { [key: string]: OrderByKey }) => {
    // Convert the object into an array of its entries
    const entries = Object.entries(obj).map(([key, value]) => ({ key, ...value }))

    // Sort the array
    const sortedEntries = sortByOrderKey(entries)

    // Convert the sorted array back into an object
    const sortedObj: { [key: string]: OrderByKey } = {}
    for (const entry of sortedEntries) {
        const { key, ...rest } = entry as any
        sortedObj[key] = rest
    }

    return sortedObj
}

export const handlePriceCurrency = (value: string, currency: string) => {
    const [_, price] = value.split('$')
    return `${currency}$ ${price}`
}

export const isCommercialInvoiceAndDocShipment = (
    userCommercialInvoice: boolean | null,
    lazrCommercialInvoice: boolean | null,
    transportType?: TransportType,
) => {
    if (transportType === TransportType.LTL || transportType === TransportType.FTL) {
        if (!userCommercialInvoice && !lazrCommercialInvoice) {
            return CommercialInvoiceAndDocShipment.CUSTOMSBROKER
        }

        if (!userCommercialInvoice && lazrCommercialInvoice) {
            return CommercialInvoiceAndDocShipment.SHOWALL
        }

        if (userCommercialInvoice) {
            return CommercialInvoiceAndDocShipment.CUSTOMSBROKER
        }
    }
    return CommercialInvoiceAndDocShipment.SHOWALL
}
