import { Country } from '@lazr/openapi-client'
import { User, OtherUser, Organization, OrderInfo } from '../../model'
import { UserRole } from '@lazr/enums'

export const FILTERED_ACCESSORIALS = ['NONCONV']

export const OLD_UI_UNSUPPORTED_ACCESSORIALS = [
    ...FILTERED_ACCESSORIALS,
    'BATTERY',
    'DRYICE',
    'HAZM',
    'HLDPU',
    'CALLTAG',
]
export const orderThreePlAgentList = (user: User | null, threePlAgentList: OtherUser[]) => {
    const threePlAgentListSort = (a: OtherUser, b: OtherUser) => {
        if (a.longFullName && b.longFullName) {
            return a.longFullName < b.longFullName ? -1 : a.longFullName > b.longFullName ? 1 : 0
        }

        return a.email < b.email ? -1 : a.email > b.email ? 1 : 0
    }
    const threePlAgentListFilter = (agent: OtherUser) => {
        if (agent.longFullName && user?.longFullName){
            return agent.longFullName !== user.longFullName
        }

        return agent.email !== user?.email
    }

    const userAsOtherUser = user ? [ new OtherUser(user) ] : []
    const agentListOrdered = threePlAgentList.filter(threePlAgentListFilter).sort(threePlAgentListSort)
    const userWasFilteredOut = threePlAgentList.length !== agentListOrdered.length

    return userWasFilteredOut ? userAsOtherUser.concat(agentListOrdered) : agentListOrdered
}

const unAssignOption = [ new OtherUser({
    id: '',
    email: '',
    organizationId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    longFullName: '-- UNASSIGN --',
}) ]

export const isOrganizationAdmin = (user: User, userOrganization: Organization | null) =>
    (user?.permissions.organizationRole.role === UserRole.ADMIN ||
    user?.getImpersonatingInfo().isAdmin) ?? false

export const threePlAgentOptions = (
    user: User | null,
    userOrganization: Organization | null,
    order: OrderInfo | null,
    agents: OtherUser[],
) => {
    if (!user || !order) {
        return []
    }

    const isAdmin = isOrganizationAdmin(user, userOrganization)
    const isAssigned = !!order.threePlAgent
    const isAssignedToId = order.threePlAgent && order.threePlAgent.id

    const allAgents = orderThreePlAgentList(user, agents)
    const allAgentsExceptAssigned = allAgents.filter((agent) => agent.id !== isAssignedToId)

    const userAsOtherUser = new OtherUser(user)

    const optionList: OtherUser[] = []

    if (!isAdmin && isAssigned && isAssignedToId === user.id){
        return optionList.concat(unAssignOption)
    }

    if (!isAdmin && !isAssigned){
        return optionList.concat(userAsOtherUser)
    }

    if (isAdmin && isAssigned) {
        return optionList.concat(unAssignOption).concat(allAgentsExceptAssigned)
    }

    if (isAdmin && !isAssigned) {
        return optionList.concat(allAgents)
    }

    return optionList
}

