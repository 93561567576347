import { OrganizationStatus, OrganizationType, UserStatus, UserTitle, UuidV4 } from '@lazr/openapi-client'
import { CofaceStatus, LabelFormatType, LabelSizeType, Onboarding, UIAccess } from '@lazr/enums'
import { Currency } from '@/app/model/Currency'
import { OrganizationRole } from '@/app/model/OrganizationPermission'

export interface OrganizationList {
    organizations: Organization[]
    total?: number
}

export interface OrganizationProps {
    id: UuidV4
    name: string
    slug: string
    description: string
    type: OrganizationType
    groupName: UuidV4
    adminGroupName: UuidV4
    registerUrl: string
    onboarding: Onboarding
    hasEdiSupport: boolean
    sendNotificationToOwner: boolean
    sendNotificationToList: boolean
    status: OrganizationStatus
    updatedAt: string
    createdAt: string
    adminUserId: UuidV4
    adminLongFullName: string | null
    adminEmail: string
    adminStatus: UserStatus | null
    adminTitle: UserTitle | null
    repUserId?: UuidV4 | null
    repLongFullName?: string | null
    repEmail?: string | null
    repStatus?: UserStatus | null
    repTitle?: UserTitle | null
    numberOfPastDueInvoices?: number
    numberOfPastDueConsolidations?: number
    totalNumberOfSubmittedFreightOrders?: number
    totalNumberOfSubmittedParcelOrders?: number
    cofaceStatus?: CofaceStatus | null
    cofaceCreditLimit?: number | null
    maxFreightOrders?: number | null
    maxParcelOrders?: number | null
    creditLimit?: number | null
    creditInUseCad?: number | null
    defaultBillingCurrency: Currency | null
    roles: OrganizationRole[]
    lastGhostAt?: string | null
    lastOrderAt?: string | null
    lastLoginAt?: string | null
    numberOfUsers?: number
    labelFormatType?: LabelFormatType | null
    labelSizeType?: LabelSizeType | null
    uiAccess: UIAccess
    useImperialUnit?: boolean | null
}

export class Organization {
    public id: UuidV4
    public name: string
    public slug: string
    public description: string
    public type: OrganizationType
    public groupName: UuidV4
    public adminGroupName: UuidV4
    public registerUrl: string
    public onboarding: Onboarding
    public hasEdiSupport: boolean
    public sendNotificationToOwner: boolean
    public sendNotificationToList: boolean
    public status: OrganizationStatus
    public updatedAt: string
    public createdAt: string
    public adminUserId: UuidV4
    public adminLongFullName: string | null
    public adminEmail: string
    public adminStatus: UserStatus | null
    public adminTitle: UserTitle | null
    public repUserId?: UuidV4 | null
    public repLongFullName?: string | null
    public repEmail?: string | null
    public repStatus?: UserStatus | null
    public repTitle?: UserTitle | null
    public numberOfPastDueInvoices?: number
    public numberOfPastDueConsolidations?: number
    public totalNumberOfSubmittedParcelOrders?: number
    public totalNumberOfSubmittedFreightOrders?: number
    public cofaceStatus?: CofaceStatus | null
    public cofaceCreditLimit?: number | null
    public maxFreightOrders?: number | null
    public maxParcelOrders?: number | null
    public creditLimit?: number | null
    public creditInUseCad?: number | null
    public defaultBillingCurrency: Currency | null
    public roles: OrganizationRole[]
    public lastGhostAt?: string | null
    public lastOrderAt?: string | null
    public lastLoginAt?: string | null
    public numberOfUsers?: number
    public labelFormatType?: LabelFormatType | null
    public labelSizeType?: LabelSizeType | null
    public uiAccess: UIAccess
    public useImperialUnit?: boolean | null

    constructor (props: OrganizationProps) {
        this.id = props.id
        this.name = props.name
        this.slug = props.slug
        this.description = props.description
        this.type = props.type
        this.groupName = props.groupName
        this.adminGroupName = props.adminGroupName
        this.registerUrl = props.registerUrl
        this.onboarding = props.onboarding
        this.hasEdiSupport = props.hasEdiSupport
        this.sendNotificationToOwner = props.sendNotificationToOwner
        this.sendNotificationToList = props.sendNotificationToList
        this.status = props.status
        this.updatedAt = props.updatedAt
        this.createdAt = props.createdAt
        this.adminUserId = props.adminUserId
        this.adminLongFullName = props.adminLongFullName
        this.adminEmail = props.adminEmail
        this.adminStatus = props.adminStatus
        this.adminTitle = props.adminTitle
        this.repUserId = props.repUserId
        this.repLongFullName = props.repLongFullName
        this.repEmail = props.repEmail
        this.repStatus = props.repStatus
        this.repTitle = props.repTitle
        this.numberOfPastDueInvoices = props.numberOfPastDueInvoices
        this.numberOfPastDueConsolidations = props.numberOfPastDueConsolidations
        this.totalNumberOfSubmittedParcelOrders = props.totalNumberOfSubmittedParcelOrders
        this.totalNumberOfSubmittedFreightOrders = props.totalNumberOfSubmittedFreightOrders
        this.cofaceStatus = props.cofaceStatus
        this.cofaceCreditLimit = props.cofaceCreditLimit
        this.maxFreightOrders = props.maxFreightOrders
        this.maxParcelOrders = props.maxParcelOrders
        this.creditLimit = props.creditLimit
        this.creditInUseCad = props.creditInUseCad
        this.defaultBillingCurrency = props.defaultBillingCurrency
        this.roles = props.roles
        this.lastGhostAt = props.lastGhostAt
        this.lastOrderAt = props.lastOrderAt
        this.lastLoginAt = props.lastLoginAt
        this.numberOfUsers = props.numberOfUsers
        this.labelFormatType = props.labelFormatType
        this.labelSizeType = props.labelSizeType
        this.uiAccess = props.uiAccess
        this.useImperialUnit = props.useImperialUnit
    }

    isSystem = (): boolean => this.type === OrganizationType.SYSTEM
    isThreePl = (): boolean => this.type === OrganizationType.THREE_PL
    isClient = (): boolean => this.type === OrganizationType.CLIENT
    isThreePlOrSystemAsThreePl = (threePlId?: UuidV4): boolean => this.isThreePl() || (this.isSystem() && threePlId === this.id)
    isClientOrSystemAsClient = (clientId?: UuidV4): boolean => this.isClient() || (this.isSystem() && clientId === this.id)
    hasCostPermissions = (): boolean =>
        [ OrganizationType.SYSTEM, OrganizationType.THREE_PL ].includes(this.type)
    hasPriceReviewPermissions = (): boolean =>
        [ OrganizationType.SYSTEM, OrganizationType.THREE_PL ].includes(this.type)
    hasBuildInvoicePermissions = (): boolean =>
        [ OrganizationType.SYSTEM, OrganizationType.THREE_PL ].includes(this.type)
    hasCreateCustomQuotePermissions = (): boolean =>
        [ OrganizationType.SYSTEM, OrganizationType.THREE_PL ].includes(this.type)
    hasCarrierQuoteReferenceVisibility = (): boolean =>
        [ OrganizationType.SYSTEM, OrganizationType.THREE_PL ].includes(this.type)
    hasUploadInvoiceDocumentPermissions = (): boolean =>
        [ OrganizationType.SYSTEM, OrganizationType.THREE_PL ].includes(this.type)

    get adminLongFullNameWithTitle (): string {
        return this.getAdminLongFullNameWithTitle()
    }

    private getAdminLongFullNameWithTitle = (): string => {
        const nameParts = []
        if (this.adminTitle) {
            nameParts.push(this.adminTitle)
        }

        if (this.adminLongFullName) {
            nameParts.push(this.adminLongFullName)
        }

        return nameParts.join(' ')
    }

    get repLongFullNameWithTitle (): string {
        return this.getRepLongFullNameWithTitle()
    }

    private getRepLongFullNameWithTitle = (): string => {
        const nameParts = []
        if (this.repLongFullName) {
            if (this.repTitle) {
                nameParts.push(this.repTitle)
            }
            nameParts.push(this.repLongFullName)
        }

        return nameParts.join(' ')
    }
}
