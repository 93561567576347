/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    'Legal name': 'Legal name',
    'Credit Application': 'Credit Application',
    Submit: 'Submit',
    'Credit Application Failed' : 'Credit Application Failed',
    'Credit Application Submitted': 'Credit Application Submitted',
    'There is an error occurred in the process, please contact support for solution.': 'There is an error occurred in the process, please contact support for solution.',
    'Congrats! You have successfully submitted your credit application.': 'Congrats! You have successfully submitted your credit application.',
    'Close': 'Close',
}
