/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    orders: 'Orders',
    order:'Order',
    'schedule-pickup': `Schedule Pickup`,
    invoices:'Invoices',
    'invoice-csv-batches':'Invoices CSV Batches',
    'tracking-update-csv-batches':'Tracking updates CSV batches',
    addressbook : "Address Book",
    address:'Address',
    organizations : "Organizations",
    organization : 'Organization',
    edi : 'EDI',
    account:'Account',
    'organization-management':'Organization management',
    'system-dashboard':'System Dashboard',
    'consolidation-payment':'Consolidation Payment',
    agreements: 'Agreements',
}
