import { UuidV4, UserTitle, OrganizationStatus } from '@lazr/openapi-client'
import { UserActivityStatus, UserRole, UserStatus } from '@lazr/enums'
import { OrganizationPermission } from '../model'

export interface UserByOrganization {
    users: UserListItem[]
    total: number
}

export interface UserListItemUserLane {
    id: UuidV4
    userId: UuidV4
    organizationId: UuidV4
}

export interface UserListItemProps {
    id: UuidV4
    activityStatus: UserActivityStatus | null
    email: string
    firstName: string | null
    role?: UserRole | null
    jobTitle?: string | null
    lastActivity?: string | null
    lastName: string | null
    middleName: string | null
    organizationId: UuidV4
    organizationName?: string | null
    organizationStatus?: OrganizationStatus | null
    phoneNumber?: string | null
    pictureUrl?: string | null
    status?: UserStatus | null
    title: UserTitle | null
    defaultBillingAddressId: UuidV4 | null
    defaultShippingAddressId: UuidV4 | null
    permissions: OrganizationPermission
    organizationRoleId?: string | null
    lanes: UserListItemUserLane[]
    shortFullName?: string | null
    longFullName?: string | null
}

export class UserListItem {
    public id: UuidV4
    public activityStatus: UserActivityStatus | null
    public email: string
    public firstName: string | null
    public role?: UserRole | null
    public jobTitle?: string | null
    public lastActivity?: string | null
    public lastName: string | null
    public middleName: string | null
    public nickname?: string | null
    public organizationId: UuidV4
    public organizationName?: string | null
    public organizationStatus?: OrganizationStatus | null
    public phoneNumber?: string | null
    public pictureUrl?: string | null
    public status?: UserStatus | null
    public title: UserTitle | null
    public defaultBillingAddressId: UuidV4 | null
    public defaultShippingAddressId: UuidV4 | null
    public organizationRoleId? : string | null
    public permissions: OrganizationPermission
    public lanes: UserListItemUserLane[]
    private internalLongFullName?: string
    private internalShortFullName?: string

    constructor (props: UserListItemProps) {
        this.id = props.id
        this.activityStatus = props.activityStatus
        this.email = props.email
        this.firstName = props.firstName
        this.jobTitle = props.jobTitle
        this.lastActivity = props.lastActivity
        this.lastName = props.lastName
        this.middleName = props.middleName
        this.organizationId = props.organizationId
        this.organizationName = props.organizationName
        this.organizationStatus = props.organizationStatus
        this.phoneNumber = props.phoneNumber
        this.pictureUrl = props.pictureUrl
        this.status = props.status
        this.title = props.title
        this.defaultBillingAddressId = props.defaultBillingAddressId
        this.defaultShippingAddressId = props.defaultShippingAddressId
        this.permissions = props.permissions
        this.organizationRoleId = props.organizationRoleId
        this.role = props.role
        this.lanes = props.lanes
    }

    get longFullNameWithTitle (): string {
        return this.getLongFullNameWithTitle()
    }

    private getLongFullNameWithTitle = (): string => {
        const nameParts = []
        if (this.title) {
            nameParts.push(this.title)
        }

        if (this.firstName) {
            nameParts.push(this.firstName)
        }

        if (this.middleName) {
            nameParts.push(this.middleName)
        }

        if (this.lastName) {
            nameParts.push(this.lastName)
        }

        return nameParts.join(' ')
    }

    get shortFullName (): string {
        if (!this.internalShortFullName) {
            this.internalShortFullName = this.getShortFullName()
        }

        return this.internalShortFullName
    }

    set shortFullName (shortFullName: string) {
        this.internalShortFullName = shortFullName
    }

    private getShortFullName = (): string => {
        const nameParts = []

        if (this.firstName) {
            nameParts.push(this.firstName)
        }

        if (this.lastName) {
            nameParts.push(this.lastName)
        }

        return nameParts.join(' ')
    }
}
