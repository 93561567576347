import { logger } from '@/app/logger'
import { Search, SearchProps, SearchRfqAccessorial, SearchRfqHandlingUnit } from '@/app/model'
import { PreparedSearch } from '@/app/model/PreparedSearch'
import {
    AddressType,
    ApiError,
    B13aFilingOption,
    BillDutiesToParty,
    Country,
    CoverageCommodityType,
    Currency,
    EquipmentType,
    ErrorResponse,
    ExportComplianceStatement,
    GetSearchByIdResponse,
    HazmatClass,
    HazmatMode,
    ImportExportType,
    IncotermsType,
    NonDeliveryHandlingType,
    PaymentTypeOption,
    ReasonForExportType,
    SearchResponse,
    SearchService,
    TransportType,
    UuidV4,
    UuidV5,
    ShipmentDocumentType,
    PreparedSearchRequest,
} from '@lazr/openapi-client'
import { isMailingService } from '@lazr/utilities'
import { handleUnauthorizedException } from './index'
import { LabelSizeType, QuoteCoverageType } from '@lazr/enums'

export interface SearchAttributes {
    preparedSearchId?: UuidV4
    isBookedWithCoverage?: boolean
    quoteCoverageType?: QuoteCoverageType | null
    isCoverageExclusionsAccepted?: boolean
    customQuoteRequested?: boolean
    ediOrderId?: null | UuidV5
    transportType?: TransportType
    equipmentType?: EquipmentType | null
    coverageCommodityType?: CoverageCommodityType | null
    shipmentCoverageValue?: number | null
    originStreetAddress?: string | null
    originStreetAddress2?: string | null
    originStreetAddress3?: string | null
    originCity?: string | null
    originState?: string | null
    originCountry?: Country
    originPostalCode?: string | null
    originAddressType?: AddressType | null
    originDetectedAddressType?: AddressType | null
    originPickupDate?: string | null
    originPickupDateTimezone?: string
    originContactName?: string | null
    originContactEmails?: string[] | null
    originContactPhone?: string | null
    originContactPhoneExtension?: string | null
    originCompanyName?: string | null
    originNotifyShipper?: boolean
    originPickupOpenTime?: string | null
    originPickupCloseTime?: string | null
    originPickupInstructions?: string | null
    originPoNumber?: string | null
    originWarehouseName?: string | null
    destinationStreetAddress?: string | null
    destinationStreetAddress2?: string | null
    destinationStreetAddress3?: string | null
    destinationCity?: string | null
    destinationState?: string | null
    destinationCountry?: Country
    destinationPostalCode?: string | null
    destinationAddressType?: AddressType | null
    destinationDetectedAddressType?: AddressType | null
    destinationContactName?: string | null
    destinationContactEmails?: string[] | null
    destinationContactPhone?: string | null
    destinationContactPhoneExtension?: string | null
    destinationCompanyName?: string | null
    destinationNotifyReceiver?: boolean
    destinationDeliveryOpenTime?: string | null
    destinationDeliveryCloseTime?: string | null
    destinationDeliveryInstructions?: string | null
    destinationPoNumber?: string | null
    destinationWarehouseName?: string | null
    billingCurrency?: Currency
    originAccessorials?: SearchRfqAccessorial[]
    destinationAccessorials?: SearchRfqAccessorial[]
    cargo?: Partial<SearchRfqHandlingUnit>[]
    clientNumber?: string | null
    threePlNumber?: string | null
    customsBroker?: string | null
    billDutiesToParty?: BillDutiesToParty | null
    documentsOnlyIndicator?: null | boolean
    customsTotalValue?: number | null
    dutyBillToCity?: string | null
    dutyBillToContactEmail?: string | null
    dutyBillToCompanyName?: string | null
    dutyBillToContactName?: string | null
    dutyBillToContactPhone?: string | null
    dutyBillToContactPhoneExt?: string | null
    dutyBillToCountry?: Country | null
    dutyBillToName?: string | null
    dutyBillToPostalCode?: string | null
    dutyBillToState?: string | null
    dutyBillToStreetAddress?: string | null
    dutyBillToStreetAddress2?: string | null
    dutyBillToStreetAddress3?: string | null
    dutyBillToAccountNumber?: string | null
    dutyBusinessRelated?: null | boolean
    dutyCurrency?: Currency | null
    hazmatClass?: HazmatClass | null
    hazmatDeclarationDocumentIndicator?: null | boolean
    hazmatMode?: HazmatMode | null
    importExportType?: ImportExportType | null
    etdEnabled?: boolean | null
    commercialInvoiceDocumentIndicator?: boolean | null
    commercialInvoiceUserProvided?: boolean | null
    useThermalPrinter?: boolean | null
    labelSizeType?: LabelSizeType | null
    b13aFilingOption?: B13aFilingOption | null
    exportComplianceStatement?: ExportComplianceStatement | null
    customsBrokerCity?: string | null
    customsBrokerEmail?: string | null
    customsBrokerContactName?: string | null
    customsBrokerContactPhone?: string | null
    customsBrokerContactPhoneExt?: string | null
    customsBrokerCountry?: Country | null
    customsBrokerPostalCode?: string | null
    customsBrokerState?: string | null
    customsBrokerStreetAddress?: string | null
    customsBrokerStreetAddress2?: string | null
    customsBrokerStreetAddress3?: string | null
    customsBrokerAccountNumber?: string | null
    taxIdentificationNumber?: string | null
    consigneeTaxIdentificationNumber?: string | null
    thirdPartyTaxIdentificationNumber?: string | null
    reasonForExportType?: ReasonForExportType | null
    nonDeliveryHandlingType?: NonDeliveryHandlingType | null
    otherReasonForExport?: string | null
    incotermsType?: IncotermsType | null
    paymentType?: PaymentTypeOption | null
    selectedLaneId?: UuidV4
    originalShipmentCoverageValueCurrency?: Currency | null
    isReturn?: boolean
    collectData?: {
        carrierId: UuidV4
        accountNumber: string
    }
    marketPlaceVersion?: number
    shipmentDocumentType?: ShipmentDocumentType | null
    envelopeConfirmed?: boolean | null
    holdForPickup?: HoldForPickup
}

interface HoldForPickup {
    carrierId: UuidV4 | null
    address: HoldForPickupAddress | null
}

interface HoldForPickupAddress {
    companyName: string
    phone: string
    phoneExtension: string | null
    streetAddress: string
    streetAddress2: string | null
    streetAddress3: string | null
    city: string
    state: string
    country: Country
    postalCode: string
}

const isGetSearchByIdResponse = (response: SearchResponse | GetSearchByIdResponse): response is GetSearchByIdResponse =>
    (response as GetSearchByIdResponse).rfq.quotes !== undefined

const mapToSearch = (searchData: SearchResponse | GetSearchByIdResponse): SearchProps => ({
    ...searchData,
    rfq: {
        ...searchData.rfq,
        quotes: isGetSearchByIdResponse(searchData) ? searchData.rfq.quotes : [],
        reasons: isGetSearchByIdResponse(searchData) ? searchData.rfq.reasons : [],
        handlingUnits: searchData.rfq.handlingUnits,
        customsDetails: searchData.rfq.customsDetails,
    },
    collectData: (searchData?.collectData && searchData.collectData) ?? null,
})

export const SearchApiService = Object.freeze({
    getById: async (id: UuidV4, includeCoverages = false, clone = false): Promise<Search> => {
        let getSearchResponse
        try {
            getSearchResponse = await SearchService.getSearchById(id, includeCoverages)
            console.debug('SearchApiService > getById > getSearchResponse', getSearchResponse)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getSearchResponse', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || `Unable to retrieve searchId:${id}`)
            }
            throw new Error(`Unable to retrieve searchId:${id}`)
        }

        if (!getSearchResponse.data) {
            logger.debug(getSearchResponse)
            throw new Error(`Unable to retrieve searchId:${id}`)
        }

        return new Search(mapToSearch(getSearchResponse.data))
    },
    getPreparedSearchById: async (id: UuidV4, clone = false): Promise<PreparedSearch> => {
        let getPreparedSearchByIdResponse
        try {
            getPreparedSearchByIdResponse = await SearchService.getPreparedSearchById(id, clone)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getPreparedSearchByIdResponse', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || `Unable to retrieve searchId:${id}`)
            }
            throw new Error(`Unable to retrieve searchId: ${id}`)
        }

        if (!getPreparedSearchByIdResponse.data) {
            logger.debug(getPreparedSearchByIdResponse)
            throw new Error(`Unable to retrieve preparedSearchId: ${id}`)
        }

        const preparedSearch = {
            status: null,
            ...getPreparedSearchByIdResponse.data.preparedSearch,

            rfq: {
                status: null,
                ...getPreparedSearchByIdResponse.data.preparedSearch.rfq,
                originContactEmails: getPreparedSearchByIdResponse.data.preparedSearch.rfq.originContactEmails
                    ? getPreparedSearchByIdResponse.data.preparedSearch.rfq.originContactEmails
                    : [],
                destinationContactEmails: getPreparedSearchByIdResponse.data.preparedSearch.rfq.destinationContactEmails
                    ? getPreparedSearchByIdResponse.data.preparedSearch.rfq.destinationContactEmails
                    : [],
                paymentType: getPreparedSearchByIdResponse.data.preparedSearch.rfq.paymentType ?? null,
            },
            collectData: getPreparedSearchByIdResponse.data.preparedSearch.collectData,
        }

        return new PreparedSearch(preparedSearch)
    },
    getSecurePreparedSearchById: async (id: UuidV4, clone = false): Promise<PreparedSearch> => {
        let getPreparedSearchByIdResponse
        try {
            getPreparedSearchByIdResponse = await SearchService.getSecurePreparedSearchById(id, clone)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getSecurePreparedSearchById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || `Unable to retrieve searchId:${id}`)
            }
            throw new Error(`Unable to retrieve searchId: ${id}`)
        }

        if (!getPreparedSearchByIdResponse.data) {
            logger.debug(getPreparedSearchByIdResponse)
            throw new Error(`Unable to retrieve preparedSearchId: ${id}`)
        }

        const preparedSearch = {
            ...getPreparedSearchByIdResponse.data.preparedSearch,
            status: null,
            rfq: {
                status: null,
                ...getPreparedSearchByIdResponse.data.preparedSearch.rfq,
                originContactEmails: getPreparedSearchByIdResponse.data.preparedSearch.rfq.originContactEmails
                    ? getPreparedSearchByIdResponse.data.preparedSearch.rfq.originContactEmails
                    : [],
                destinationContactEmails: getPreparedSearchByIdResponse.data.preparedSearch.rfq.destinationContactEmails
                    ? getPreparedSearchByIdResponse.data.preparedSearch.rfq.destinationContactEmails
                    : [],
                paymentType: getPreparedSearchByIdResponse.data.preparedSearch.rfq.paymentType,
            },
            collectData: getPreparedSearchByIdResponse.data.preparedSearch.collectData,
            isReturn: getPreparedSearchByIdResponse.data.preparedSearch.isReturn,
        }

        return new PreparedSearch(preparedSearch)
    },
    search: async (
        searchAttributes: SearchAttributes,
        startSearchQuote = true,
        forceInsertSearchQuote = false,
        clone = false
    ): Promise<Search> => {
        //console.debug('searchAttributes', searchAttributes)
        /* NORMAL FLOW */
        if (
            startSearchQuote &&
            !forceInsertSearchQuote &&
            !searchAttributes.preparedSearchId &&
            (searchAttributes.customQuoteRequested === undefined ||
                searchAttributes.isBookedWithCoverage === undefined ||
                !searchAttributes.transportType ||
                (searchAttributes.transportType !== TransportType.ENVELOPE &&
                    searchAttributes.transportType !== TransportType.PAK &&
                    searchAttributes.transportType !== TransportType.PARCEL &&
                    searchAttributes.transportType !== TransportType.OTHER &&
                    !searchAttributes.equipmentType) ||
                !searchAttributes.originCity ||
                !searchAttributes.originCountry ||
                !searchAttributes.originPostalCode ||
                !searchAttributes.originPickupDate ||
                !searchAttributes.destinationCity ||
                !searchAttributes.destinationCountry ||
                !searchAttributes.destinationPostalCode ||
                !searchAttributes.billingCurrency ||
                !searchAttributes.originAccessorials ||
                !searchAttributes.destinationAccessorials ||
                !searchAttributes.cargo)
        ) {
            throw new Error('Required fields missing.')
        }
        //TODO: if there's an error here in the map it is not handled properly
        const cargo = searchAttributes.cargo?.map((handlingUnit) => {
            if (!searchAttributes.preparedSearchId) {
                if (!searchAttributes.transportType) {
                    throw new Error('Transport type not defined.')
                }
                const isFTL = searchAttributes.transportType
                if (
                    searchAttributes.transportType === TransportType.ENVELOPE
                    && (!handlingUnit.description || !handlingUnit.weight)
                 ) {
                    throw new Error('Required fields missing.')
                } else if (
                    searchAttributes.transportType === TransportType.PARCEL
                    && (!handlingUnit.description ||
                        !handlingUnit.totalWeightUnit ||
                        !handlingUnit.totalWeight ||
                        !handlingUnit.weightUnit ||
                        !handlingUnit.weight ||
                        !handlingUnit.heightUnit ||
                        !handlingUnit.height ||
                        !handlingUnit.widthUnit ||
                        !handlingUnit.width ||
                        !handlingUnit.lengthUnit ||
                        !handlingUnit.length ||
                        !handlingUnit.quantity)
                ) {
                    throw new Error('Required fields missing.')
                }

                if (
                    searchAttributes.transportType === TransportType.PAK &&
                    (!handlingUnit.totalWeightUnit ||
                        !handlingUnit.totalWeight ||
                        !handlingUnit.weightUnit ||
                        !handlingUnit.weight ||
                        !handlingUnit.quantity)
                ) {
                    throw new Error('Required fields missing.')
                }

                if (!isMailingService(searchAttributes.transportType)) {
                    let condition =
                        !handlingUnit.description ||
                        !handlingUnit.packageType ||
                        !handlingUnit.class ||
                        !handlingUnit.totalWeightUnit ||
                        !handlingUnit.totalWeight ||
                        !handlingUnit.weightUnit ||
                        !handlingUnit.weight ||
                        !handlingUnit.quantity
                    if (searchAttributes.transportType !== TransportType.FTL) {
                        condition =
                            condition ||
                            !handlingUnit.heightUnit ||
                            !handlingUnit.height ||
                            !handlingUnit.widthUnit ||
                            !handlingUnit.width ||
                            !handlingUnit.lengthUnit ||
                            !handlingUnit.length ||
                            !handlingUnit.pieces
                    }

                    if (condition) {
                        throw new Error('Required fields missing.')
                    }
                }
            }

            return {
                poNumber: handlingUnit.poNumber || null,
                description: handlingUnit.description || null,
                packageType: handlingUnit.packageType || null,
                nmfc: handlingUnit.nmfc || null,
                nmfcSub: handlingUnit.nmfcSub || null,
                class: handlingUnit.class || null,
                totalWeightUnit: handlingUnit.totalWeightUnit || null,
                totalWeight: handlingUnit.totalWeight || null,
                weightUnit: handlingUnit.weightUnit || null,
                weight: handlingUnit.weight || null,
                heightUnit: handlingUnit.heightUnit || null,
                height: handlingUnit.height || null,
                widthUnit: handlingUnit.widthUnit || null,
                width: handlingUnit.width || null,
                lengthUnit: handlingUnit.lengthUnit || null,
                length: handlingUnit.length || null,
                pieces: handlingUnit.pieces || null,
                quantity: handlingUnit.quantity || null,
                envelopeType: handlingUnit.envelopeType || null,
                accessorials:
                    handlingUnit.accessorials?.map((accessorial) => ({
                        isRemovable: accessorial.isRemovable,
                        accessorialId: accessorial.accessorial.id,
                    })) || [],
                dryIce: handlingUnit.dryIce?.unit && handlingUnit.dryIce?.value ? handlingUnit.dryIce : null,
                battery: handlingUnit.battery?.material && handlingUnit.battery?.packing ? handlingUnit.battery : null,
                hazmatAccessibiltyType: handlingUnit.hazmatAccessibiltyType ?? null,
                freightHazmatUn: handlingUnit.freightHazmatUn ?? null,
                freightHazmatScientificName: handlingUnit.freightHazmatScientificName ?? null,
                freightHazmatPackagingGroup: handlingUnit.freightHazmatPackagingGroup ?? null,
                freightHazmatEmergencyContactNumber: handlingUnit.freightHazmatEmergencyContactNumber ?? null,
                freightHazmatClass: handlingUnit.freightHazmatClass ?? null,
            }
        })

        let searchResponse
        try {
            if (searchAttributes.preparedSearchId) {
                searchResponse = await SearchService.search(
                    {
                        preparedSearchId: searchAttributes.preparedSearchId,
                        ...searchAttributes,
                        originCity: searchAttributes.originCity ?? undefined,
                        originState: searchAttributes.originState ?? undefined,
                        originContactEmails: searchAttributes.originContactEmails ?? undefined,
                        originPostalCode: searchAttributes.originPostalCode ?? undefined,
                        originPickupDate: searchAttributes.originPickupDate ?? undefined,
                        originPickupDateTimezone: searchAttributes.originPickupDateTimezone ?? undefined,
                        destinationCity: searchAttributes.destinationCity ?? undefined,
                        destinationState: searchAttributes.destinationState ?? undefined,
                        destinationContactEmails: searchAttributes.destinationContactEmails ?? undefined,
                        destinationPostalCode: searchAttributes.destinationPostalCode ?? undefined,
                        originAccessorials: searchAttributes.originAccessorials?.map((accessorial) => ({
                            isRemovable: accessorial.isRemovable,
                            accessorialId: accessorial.accessorial.id,
                        })),
                        destinationAccessorials: searchAttributes.destinationAccessorials?.map((accessorial) => ({
                            isRemovable: accessorial.isRemovable,
                            accessorialId: accessorial.accessorial.id,
                        })),
                        cargo: cargo,
                        paymentType: searchAttributes.paymentType,
                        collectData: searchAttributes.collectData,
                    } as PreparedSearchRequest,
                    startSearchQuote,
                    forceInsertSearchQuote,
                    clone
                )
            } else {
                //console.debug('search call 2', searchAttributes)
                searchResponse = await SearchService.search(
                    {
                        ...searchAttributes,
                        originAccessorials: searchAttributes.originAccessorials?.map((accessorial) => ({
                            isRemovable: accessorial.isRemovable,
                            accessorialId: accessorial.accessorial.id,
                        })),
                        destinationAccessorials: searchAttributes.destinationAccessorials?.map((accessorial) => ({
                            isRemovable: accessorial.isRemovable,
                            accessorialId: accessorial.accessorial.id,
                        })),
                    } as any,
                    startSearchQuote,
                    forceInsertSearchQuote,
                    clone
                )
            }
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.error('search', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to proceed the search')
            }
            throw new Error('Unable to proceed the search')
        }
        if (!searchResponse.data) {
            logger.debug('search', JSON.stringify(searchResponse, null, 4))
            throw new Error('Unable to proceed the search')
        }

        return new Search(mapToSearch(searchResponse.data))
    },
})
