import { ApiServiceAction } from '@lazr/enums'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback'
import { CreditApplicationApiService } from '../../../../service/ApiService'
import { CreditApplicationAttributes } from '../../../../model/CreditApplication'
import { UuidV4 } from '@lazr/types'
import { SubscribeProps } from '../../schedule-pickup/hooks/useSchedulePickup'

export interface ReturnCreditApplicationDataProps {
    id: UuidV4 | null
    error?: any,
    loaded?: boolean
}

export interface ReturnCreditApplicationProps {
    new?: ReturnCreditApplicationDataProps
}
export interface CreditApplicationProps {
    newData?: CreditApplicationAttributes
}
const useCreditApplication = (
    { newData }: CreditApplicationProps,
    action: ApiServiceAction,
): ReturnCreditApplicationProps => {

    const [ newResult, setNewResult] = useState<ReturnCreditApplicationDataProps| undefined>()

    const newCreditApplication = async (): Promise<void> => {
        try {
            if (newData) {
                const id: UuidV4 | null = await CreditApplicationApiService.submit(newData)
                if (id) {
                    setNewResult({ id, loaded: true })
                } else {
                    setNewResult({
                        id: null,
                        error: 'Internal Error',
                        loaded: false,
                    })
                }
            }
        } catch (error) {
            console.error('Error scheduling new pickup:', error)
            setNewResult({
                id: null,
                error: error,
                loaded: false,
            })
        }
    }

    const debounceCreditApplication = useDebouncedCallback(async (action) => {
        switch (action) {
            case ApiServiceAction.NEW:
                await newCreditApplication()
                break
            default:
                break
        }
    }, 0)

    // Effect to fetch data on limit and page change
    useEffect(() => {
        debounceCreditApplication(action)
    }, [debounceCreditApplication, action, newData])

    return { new: newResult }
}

export default useCreditApplication
