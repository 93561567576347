// biome-ignore lint/style/useImportType: <explanation>
import React, { useState } from 'react'

import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './SchedulePickup.i18n'
import { z } from 'zod'
import FormSimple, {
    type OnError,
    type FieldConfig,
    type SchemaField,
    SchemaRefineConfigurations,
} from '@/app/ui-new/components/Form/FormSimple'
import type { Pickup, Timezone, Country as CountryList, ServiceLevel } from '@/app/model'
import { type TransportType, ApiServiceAction, WeightUnit } from '@lazr/enums'

import type { HandlingUnitFormProps } from '@/app/ui-new/components/Form/FullComponents/HandlingUnitForm/HandlingUnitForm'
import DoubleAddressForm from '@/app/ui-new/components/Form/FullComponents/DoubleAddressForm/DoubleAddressForm'
import type { AddressFormProps } from '@/app/ui-new/components/Form/FullComponents/AddressForm/AddressForm'
import type { Carrier, UuidV4 } from '@lazr/openapi-client'
import { processAddress } from '../../helpers'
import { capitalizeEachWord } from '../../../helpers'
import AccordionSession from '@/app/ui-new/components/Accordion/AccordionSession'

import MultipleHandlingUnitForm from '@/app/ui-new/components/Form/FullComponents/MultipleHandlingUnitForm/MultipleHandlingUnitForm'
import PrimaryButton from '@/app/ui-new/components/PrimaryButton/PrimaryButton'
import { flattenSchema } from '@/app/ui-new/components/Form/helpers'
import type { ProcessDataProps } from '../../New/processData'
import type { ReturnSchedulePickupDataProps } from '../../hooks/useSchedulePickup'
import {  Grid, Box } from '@material-ui/core'

import { useHistory } from 'react-router'
import {
    Dialog,
    DialogTitle,
    DialogText,
    DialogButtonContainer
} from '@/app/ui-new/components/Dialog/Dialog'
import { useDebouncedCallback } from '../../../hooks/useDebouncedCallback'
import { convertTo24HourFormat, convertToSchedulePickupTimezone } from '@lazr/utilities'

export interface SchedulePickupProps {
    nameID: string
    defaultPickup: PickupDefaultProps
    pickup?: Pickup
}

export interface PickupDefaultProps {
    nameID: string
    carriers?: Carrier[]
    transportTypesData: []
    billDutiesToPartyData: []
    fedexLocationData: []
    fedexCarrierCodeData: []
    currencyCodeData: []
    timezoneList: Timezone[]
    countries?: CountryList[]
    processData: {
        fn: ProcessDataProps['fn']
        setReturnProcessData: ProcessDataProps['setReturnProcessData']
    }
    setCarrier?: React.Dispatch<React.SetStateAction<any>>
    setTransportType?: React.Dispatch<React.SetStateAction<TransportType | undefined>>
    serviceLevel?: ServiceLevel[]
    schedulePickupData?: ReturnSchedulePickupDataProps
    transportType? : TransportType
}

interface HandlingUnit {
    quantity?: number
    length?: number
    width?: number
    height?: number
    weight?: number
    totalWeight?: number
    averageWeight?: number
    weightUnit?: WeightUnit
}

const SchedulePickup: React.FunctionComponent<SchedulePickupProps> = ({ nameID, defaultPickup }) => {
    const { t } = useI18n(i18n)
    const history = useHistory()
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const [selectedCarrier, setSelectedCarrier] = useState<Carrier | undefined>()
    const initialIsValid: OnError = {
        data: {},
        isValid: false,
        isDirty: false,
    }
    const [isValidHandling, setIsValidHandling] = useState<OnError>(initialIsValid)
    const [isValidAddress, setIsValidAddress] = useState<OnError>(initialIsValid)
    const [isValidDefaultField, setIsValidDefaultField] = useState<OnError>(initialIsValid)
    const [isValidCustomSpecifics, setIsValidCustomSpecifics] = useState<OnError>(initialIsValid)
    const [carrierExpanded, setIsCarrierExpanded] = useState<boolean>(true)
    const [customFieldExpanded, setIsCustomFieldsExpanded] = useState<boolean>(isValidDefaultField.isDirty &&
        isValidDefaultField.isValid)

    const handleCarrierExpansion = () => {
        setIsCarrierExpanded((prevExpanded) => !prevExpanded)
    }
    const handleCustomFieldsExpansion = () => {
        setIsCustomFieldsExpanded((prevExpanded) => !prevExpanded)
    }
    const [quantityHandlingUnit, setQuantityHandlingUnit] = useState<boolean>(false)
    const [lengthHandlingUnit, setLengthHandlingUnit] = useState<boolean>(false)
    const [widthHandlingUnit, setWidthHandlingUnit] = useState<boolean>(false)
    const [heightHandlingUnit, setHeightHandlingUnit] = useState<boolean>(false)
    const [averageWeightHandlingUnit, setAverageWeightHandlingUnit] = useState<boolean>(false)
    const [weightHandlingUnit, setWeightHandlingUnit] = useState<boolean>(false)
    const [hideHandlingUnit, setHideHandlingUnit] = useState<boolean>(true)
    const [hideSpecifics, setHideSpecifics] = useState<boolean>(false)
    const [pickupAddress, setPickupAddress] = useState<AddressFormProps['address'] | undefined>()
    const [shipperAddress, setShipperAddress] = useState<AddressFormProps['address'] | undefined>()
    const [handlingUnitData, setHandlingUnitData] = useState<HandlingUnit[]>([])
    const [defaultData, setDefaultData] = useState([])
    const [customSpecificsData, setCustomSpecificsData] = useState([])
    const [forceSubmit, setForceSubmit] = useState<boolean>(false)




    const yesSchema: Record<string, SchemaField> = {
        yes: {
            type: 'checkbox',
            label: t('Yes'),
            layoutConfig: { xs: 12 },
        },
    }

    interface CarrierInfo {
        carrierName: string
        layoutConfig?: any
    }

    // Reusable fields
    const isResidential = ({ carrierName, layoutConfig }: CarrierInfo): FieldConfig[] => [
        {
            name: 'isResidential',
            hide: selectedCarrier?.name.toUpperCase() !== carrierName,
            schema: z.any(),
            groupInputs: flattenSchema(yesSchema, 'layoutConfig,label,type'),
            type: 'checkbox',
            hasOrder: false,
            required: true,
            values: '',
            layoutConfig: { xs: 12, sm: 12, ...layoutConfig },
            labelName: t('Is Residential?'),
        },
    ]

    const quantity = ({ carrierName, layoutConfig }: CarrierInfo): FieldConfig[] => [
        {
            name: 'quantity',
            hide: selectedCarrier?.name.toUpperCase() !== carrierName,
            labelName: t('Quantity'),
            required: true,
            schema:selectedCarrier?.name.toUpperCase() === carrierName ? z.string().min(1, { message: `${t('Quantity')}  ${t('is required')}` }) : z.any(),
            type: 'numberField',
            values: '',
            layoutConfig: { xs: 12, sm: 6, ...layoutConfig },
        },
    ]

    const serviceLevelId = ({ carrierName, layoutConfig }: CarrierInfo): FieldConfig[] => {
        return [
            {
                name: 'serviceLevelId',
                hide: selectedCarrier?.name.toUpperCase() !== carrierName,
                labelName: t('Service Level Code'),
                required: true,
                schema:
                    selectedCarrier?.name.toUpperCase() === carrierName ? z.string().min(1, { message: `${t('Service Level Code')}  ${t('is required')}` }) : z.any(),
                type: 'select',
                values: '',
                layoutConfig: { xs: 12, sm: 12, ...layoutConfig },
                select: {
                    data: defaultPickup.serviceLevel?.filter(value => typeof value !== 'boolean'),
                    size: 'small',
                    name: 'useId'
                },
            },
        ]
    }

    // DHL FIELD
    const fieldsDHLSpecifics: FieldConfig[] = [
        ...isResidential({ carrierName: 'DHL' }),
        {
            name: 'dutyBillToAccountNumber',
            hide: selectedCarrier?.name.toUpperCase() !== 'DHL',
            labelName: t('Bill To Account Number'),
            required: true,
            schema:
                selectedCarrier?.name.toUpperCase() === 'DHL'
                    ? z.string().min(1, { message: `${t('Bill To Account Number')}  ${t('is required')}` })
                    : z.any(),
            type: 'text',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        ...serviceLevelId({ carrierName: 'DHL', layoutConfig: { xs: 12, sm: 6 } }),
        {
            name: 'totalDeclaredValue',
            hide: selectedCarrier?.name.toUpperCase() !== 'DHL',
            labelName: t('Total Declared Value'),
            required: true,
            schema:
                selectedCarrier?.name.toUpperCase() === 'DHL'
                    ? z.string().min(1, { message: `${t('Total Declared Value')}  ${t('is required')}` })
                    : z.any(),
            type: 'text',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'totalDeclaredValueCurrency',
            hide: selectedCarrier?.name.toUpperCase() !== 'DHL',
            labelName: t('Currency'),
            required: true,
            schema:
                selectedCarrier?.name.toUpperCase() === 'DHL'
                    ? z.string().min(1, { message: `${t('Currency')}  ${t('is required')}` })
                    : z.any(),
            type: 'select',
            select: {
                name:'currency',
                data: defaultPickup.currencyCodeData,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
    ]

    // UPS FIELD
    const fieldsUPSSpecifics: FieldConfig[] = [
        ...isResidential({ carrierName: 'UPS' }),
        ...serviceLevelId({ carrierName: 'UPS'}),
    ]

    // CANADA POST FIELDS
    const fieldsCanadaPostSpecifics: FieldConfig[] = [...quantity({ carrierName: 'CANADA POST' })]

    // Fedex
    const fieldsFedexSpecifics: FieldConfig[] = [
        {
            name: 'destinationDeliveryInstructions',
            hide: selectedCarrier?.name.toUpperCase() !== 'FEDEX',
            labelName: t('Destination Delivery Instructions'),
            required: true,
            schema:
                selectedCarrier?.name.toUpperCase() === 'FEDEX'
                    ? z.string().min(1, { message: `${t('Destination Delivery Instructions')}  ${t('is required')}` })
                    : z.any(),
            type: 'textOutlined',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'location',
            hide:selectedCarrier?.name.toUpperCase() !== 'FEDEX',
            labelName: t('Location'),
            required: true,
            schema:
                selectedCarrier?.name.toUpperCase() === 'FEDEX'
                    ? z.string().min(1, { message: `${t('Location')}  ${t('is required')}` })
                    : z.any(),
            type: 'select',
            select: {
                data: defaultPickup.fedexLocationData,
                size: 'small',
                name: 'useId',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },

        ...serviceLevelId({ carrierName: 'FEDEX', layoutConfig: { xs: 12, sm: 6 } }),
        {
            name: 'carrierCode',
            hide: selectedCarrier?.name.toUpperCase() !== 'FEDEX',
            labelName: t('Carrier Code'),
            required: true,
            schema:
                selectedCarrier?.name.toUpperCase() === 'FEDEX'
                    ? z.string().min(1, { message: `${t('Carrier Code')}  ${t('is required')}` })
                    : z.any(),
            type: 'select',
            select: {
                data: defaultPickup.fedexCarrierCodeData,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
    ]

    //Nationex
    const fieldsNationexSpecifics: FieldConfig[] = [...quantity({ carrierName: 'NATIONEX' })]

    const fieldsCanparSpecifics: FieldConfig[] = [
        {
            name: 'instructions',
            hide: selectedCarrier?.name.toUpperCase() !== 'CANPAR',
            labelName: t('Origin Pickup Instructions'),
            required: true,
            schema: selectedCarrier?.name.toUpperCase() === 'CANPAR'
                ? z.string().nullable()
                : z.any(),
            type: 'textOutlined',
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
    ]

    const customSpecifics: FieldConfig[] = [
        ...fieldsDHLSpecifics,
        ...fieldsUPSSpecifics,
        ...fieldsCanadaPostSpecifics,
        ...fieldsFedexSpecifics,
        ...fieldsNationexSpecifics,
        ...fieldsCanparSpecifics,
    ]

    const initHandlingUnitField: HandlingUnitFormProps['fields'] = {
        quantity: { hide: quantityHandlingUnit, label: t('Quantity'), subLabel: t('Packages') },
        length: { hide: lengthHandlingUnit },
        width: { hide: widthHandlingUnit },
        height: { hide: heightHandlingUnit },
        averageWeight: { hide: averageWeightHandlingUnit },
        weight: { hide: weightHandlingUnit },
        totalWeight: { label: `\u00A0${t('Total Weight')} \u00A0(${t('All packages')})`, layoutConfig: { id: 'totalWeight' }, hide: false },
    }

    const getCustomSpecificsError = (data: OnError) => {
        if (!hideSpecifics) {
            setIsValidCustomSpecifics(data)
        }
    }

    const getHandlingUnitError = (data: any) => {
        if (!hideHandlingUnit && data.length > 0) {
            const isValid = isValidHandling
            isValid.isValid = false
            isValid.isDirty = true
            setIsValidHandling(isValid)
        }
    }

    const getDefaultFieldsError = (data: OnError) => {
        setIsValidDefaultField(data)
    }

    const getAddressDataError = (data: OnError) => {
        setIsValidAddress(data)
    }

    const getCustomSpecifics = (data: any) => {
        if (data) {
            setCustomSpecificsData(data)
            const isValid = isValidCustomSpecifics
            isValid.isValid = true
            isValid.isDirty = true
            setIsValidCustomSpecifics(isValid)
        }
    }
    const getHandlingUnit = (data: any) => {
        if (data.length > 0) {
            const isValid = isValidHandling
            isValid.isValid = true
            isValid.isDirty = true
            setHandlingUnitData(data)
            setIsValidHandling(isValid)
        }
    }
    // customSpecificsData
    // handlingUnitData
    // defaultData

    const handleDefaultFieldsChange = (data: any) => {
        if (data) {
            data.closeTime = convertTo24HourFormat(data.closeTime)
            data.openTime = convertTo24HourFormat(data.openTime)
            data.timezone = convertToSchedulePickupTimezone(data.timezone)

            setDefaultData(data)
            defaultPickup.setTransportType?.(data.transportType)
            const isValid = isValidDefaultField
            isValid.isValid = true
            isValid.isDirty = true
            setIsValidDefaultField(isValid)
            setIsCustomFieldsExpanded(true)
        }
    }

    const getAddressData = ({ address1, address2 }: { address1: AddressFormProps['address']; address2: AddressFormProps['address'] }) => {
        setPickupAddress(address1)
        setShipperAddress(address2)
        const isValid = isValidAddress
        isValid.isValid = true
        isValid.isDirty = true
        setIsValidAddress(isValid)
    }

    const customFields: FieldConfig[] = [
        {
            name: 'CustomCarrier',
            schema: z.any(),
            hide: selectedCarrier?.name
                ? ![
                      'CANADA POST',
                      'DHL',
                      'FEDEX',
                      'PUROLATOR',
                      'UPS',
                      'NATIONEX',
                      'CANPAR',
                  ].includes(selectedCarrier?.name.toUpperCase())
                : true,
            type: 'injectComponent', /// TODO: change to custom Form
            injectComponent: (
                <AccordionSession
                    setIsExpanded={setIsCustomFieldsExpanded}
                    handleExpansion={handleCustomFieldsExpansion}
                    isExpanded={defaultPickup?.transportType != null}
                    title={capitalizeEachWord(selectedCarrier?.name)}
                    error={isValidCustomSpecifics.isDirty && !isValidCustomSpecifics.isValid}
                    completed={isValidCustomSpecifics.isDirty && isValidCustomSpecifics.isValid}
                    endLabel={isValidCustomSpecifics.isDirty && isValidCustomSpecifics.isValid ? t('completed') : ''}
                    disabled={
                        !(isValidDefaultField.isDirty &&
                            isValidDefaultField.isValid)}
                >
                    <Grid item container spacing={5}>
                        <Grid item xs={12}>
                            <FormSimple
                                hideForm={true}
                                nameID={`${nameID}-custom-specifics`}
                                fields={customSpecifics}
                                layoutConfig={{ spacing: 3 }}
                                forceSubmit={forceSubmit}
                                onChange={getCustomSpecifics}
                                onError={getCustomSpecificsError}
                                //resetForm={resetForm}
                                debouncer={{ wait: 1 }}
                            />
                        </Grid>
                        {hideHandlingUnit === false && (
                            <>
                                <MultipleHandlingUnitForm
                                    nameID='multipleHandling'
                                    hasMultiple={false}
                                    isAverage={true}
                                    initHandlingUnitField={initHandlingUnitField}
                                    forceSubmit={forceSubmit}
                                    getError={getHandlingUnitError}
                                    getData={getHandlingUnit}

                                />
                            </>
                        )}
                    </Grid>
                </AccordionSession>
            ),
            layoutConfig: { xs: 12, sm: 12 },
        },
    ]

    const carrierFields: FieldConfig[] = [
        {
            name: 'carrierId',
            labelName: t('Select the carrier'),
            schema: z.string(),
            required: true,
            type: 'select',
            select: {
                data: defaultPickup.carriers,
                name: 'carrier',
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 12 },
        },
    ]

    const defaultFields: FieldConfig[] = [
        {
            name: 'transportType',
            labelName: t('Transport Type'),
            schema: z.string().min(1, { message: `${t('Transport Type')}  ${t('is required')}` }),
            required: true,
            type: 'select',
            select: {
                data: defaultPickup.transportTypesData,
                name: 'iconList',
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'timezone',
            labelName: t('Timezone'),
            required: true,
            schema: z.string().min(1, { message: `${t('Timezone')}  ${t('is required')}` }),
            type: 'select',
            select: {
                data: defaultPickup.timezoneList,
                size: 'small',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 6 },
        },
        {
            name: 'openTime',
            labelName: t('Open Time'),
            required: true,
            schema: z.string().min(1, { message: `${t('Open Time')}  ${t('is required')}` }),
            type: 'timeField',
            select: {
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 4 },
        },
        {
            name: 'closeTime',
            labelName: t('Close Time'),
            required: true,
            schema: z.string().min(1, { message: `${t('Close Time')}  ${t('is required')}` }),
            type: 'timeField',
            select: {
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 4 },
        },
        {
            name: 'date',
            required: true,
            labelName: t('Date'),
            schema: z.string().refine(
                (dateStr) => {
                    const inputDate = new Date(dateStr)
                    const now = new Date()

                    // Set the time part to 0 for comparison to only compare the date part
                    now.setHours(0, 0, 0, 0)
                    inputDate.setHours(0, 0, 0, 0)

                    // Ensure the input date is today or later
                    return inputDate >= now
                },
                {
                    message: t('The date must be greater than the current date'),
                },
            ),
            type: 'date',
            select: {
                size: 'medium',
            },
            values: '',
            layoutConfig: { xs: 12, sm: 4 },
        },

        ...customFields,
    ]

    /// Refine After Fields

    const schemaRefineDefaultFields: SchemaRefineConfigurations[] = [
        {
            refine: (data) => {
                const openTime = data.openTime
                const closeTime = data.closeTime

                if (!openTime || !closeTime) return true // Skip validation if one of the fields is missing

                // Helper function to validate time strings
                const isValidTime = (time: any) => /^(0?[1-9]|1[0-2]):[0-5][0-9] [APap][mM]$/.test(time)

                // Helper function to convert 12-hour format to 24-hour format
                const convertTo24Hour = (time: any) => {
                    const [timePart, modifier] = time.split(' ')
                    let [hours, minutes] = timePart.split(':').map(Number)

                    if (modifier.toLowerCase() === 'pm' && hours !== 12) {
                        hours += 12
                    }
                    if (modifier.toLowerCase() === 'am' && hours === 12) {
                        hours = 0
                    }

                    return { hours, minutes }
                }

                if (!isValidTime(openTime) || !isValidTime(closeTime)) {
                    return true // Return false if time format is invalid
                }

                const { hours: openHours, minutes: openMinutes } = convertTo24Hour(openTime)
                const { hours: closeHours, minutes: closeMinutes } = convertTo24Hour(closeTime)

                // Ensure that openTime is before closeTime
                return openHours < closeHours || (openHours === closeHours && openMinutes < closeMinutes)
            },
            options: {
                message: t('Open Time must be earlier than Close Time'),
                path: ['openTime'],
            }, //
        },
    ]
    const handleCarrierChange = (value: any) => {
        const foundCarrier = defaultPickup.carriers?.find((c) => c.id === value.carrierId)

        setIsValidHandling(initialIsValid)
        setIsValidAddress(pickupAddress ? isValidAddress : initialIsValid)
        setIsValidDefaultField(defaultData ? isValidDefaultField : initialIsValid)
        setIsValidCustomSpecifics(customSpecificsData.length> 0 ? isValidCustomSpecifics : initialIsValid)
        defaultPickup?.setCarrier?.(foundCarrier)
        // let unitFormProps: HandlingUnitFormProps['fields'];
        setSelectedCarrier(foundCarrier)
        switch (foundCarrier?.name.toUpperCase()) {
            case 'CANADA POST':
                setHideSpecifics(true)
                setHideHandlingUnit(true)
                setIsValidHandling({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })

                break
            case 'FEDEX':
                setHideHandlingUnit(false)

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setAverageWeightHandlingUnit(false)
                if(handlingUnitData.length > 0) {
                    const isValid =  !!handlingUnitData[0].weight && !!handlingUnitData[0].weightUnit && !!handlingUnitData[0].quantity
                    setIsValidHandling({
                        data: handlingUnitData,
                        isValid: isValid,
                        isDirty: !isValid,
                    })
                }
                break
            case 'DHL':
                setHideHandlingUnit(false)

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(false)
                setWidthHandlingUnit(false)
                setHeightHandlingUnit(false)
                setAverageWeightHandlingUnit(false)
                if(handlingUnitData.length > 0) {
                    const isValid =  !!handlingUnitData[0].weight && !!handlingUnitData[0].weightUnit && !!handlingUnitData[0].quantity &&
                        !!handlingUnitData[0].length && !!handlingUnitData[0].width && !!handlingUnitData[0].height
                    setIsValidHandling({
                        data: handlingUnitData,
                        isValid: isValid,
                        isDirty: !isValid,
                    })
                }
                break
            case 'NATIONEX':
                setHideSpecifics(false)
                setHideHandlingUnit(true)
                setIsValidHandling({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })
                break
            case 'PUROLATOR':
                setHideHandlingUnit(false)
                setHideSpecifics(true)

                setIsValidCustomSpecifics({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })
                if(handlingUnitData.length > 0) {
                    const isValid =  !!handlingUnitData[0].weight && !!handlingUnitData[0].weightUnit && !!handlingUnitData[0].quantity
                    setIsValidHandling({
                        data: handlingUnitData,
                        isValid: isValid,
                        isDirty: !isValid,
                    })
                }
                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setAverageWeightHandlingUnit(false)
                // unitFormProps = purolatorSpecificsHandlingUnitField;
                break
            case 'UPS':
                setHideHandlingUnit(false)

                setWeightHandlingUnit(false)
                setQuantityHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setAverageWeightHandlingUnit(false)
                // unitFormProps = upsSpecificsHandlingUnitField;
                if(handlingUnitData.length > 0) {
                    const isValid =  !!handlingUnitData[0].weight && !!handlingUnitData[0].weightUnit && !!handlingUnitData[0].quantity
                    setIsValidHandling({
                        data: handlingUnitData,
                        isValid: isValid,
                        isDirty: !isValid,
                    })
                }
                break
            case 'CANPAR':
                setHideSpecifics(true)
                setHideHandlingUnit(false)
                setLengthHandlingUnit(true)
                setWidthHandlingUnit(true)
                setHeightHandlingUnit(true)
                setIsValidHandling({
                    data: {},
                    isValid: true,
                    isDirty: true,
                })

                break
            default:
                break
        }
    }

    const handleOnSubmit = () => {
        setForceSubmit(true)
        setForceSubmit(false)
        setOpenDialog(true)
        const data = {
            carrierName: selectedCarrier?.name,
            carrierId: selectedCarrier?.id,
            defaultData,
            customSpecificsData,
            handlingUnitData,
            pickupAddressData: pickupAddress,
            shipperAddressData: shipperAddress,
        }
        if (defaultPickup.countries)
            defaultPickup.processData.fn(
                data,
                defaultPickup.countries,
                defaultPickup.processData.setReturnProcessData,
                ApiServiceAction.NEW,
            )
    }

    const debouncedHandleCarrierOnChange = useDebouncedCallback(handleCarrierChange, 500) as (value: any) => void
    return (
        <>
            <Grid item container spacing={5}>
                <Grid item xs={12}>
                    <AccordionSession
                        setIsExpanded={setIsCarrierExpanded}
                        handleExpansion={handleCarrierExpansion}
                        isExpanded={carrierExpanded}
                        title={t('Carrier')}
                        error={
                            !selectedCarrier?.name
                                ? false
                                :  isValidDefaultField.isDirty && !(isValidDefaultField.isValid && isValidCustomSpecifics.isValid && isValidHandling.isValid)
                        }
                        completed={
                            !!selectedCarrier?.name &&
                            isValidDefaultField.isDirty &&
                            isValidDefaultField.isValid &&
                            isValidCustomSpecifics.isDirty &&
                            isValidCustomSpecifics.isValid &&
                            isValidHandling.isDirty &&
                            isValidHandling.isValid
                        }
                        endLabel={
                            selectedCarrier?.name &&
                            isValidDefaultField.isDirty &&
                            isValidDefaultField.isValid &&
                            isValidCustomSpecifics.isDirty &&
                            isValidCustomSpecifics.isValid &&
                            isValidHandling.isDirty &&
                            isValidHandling.isValid
                                ? t('completed')
                                : ''
                        }
                    >
                        <Grid item container spacing={5}>
                            <Grid item xs={12}>
                                <FormSimple
                                    nameID={`${nameID}-form-carrier`}
                                    fields={carrierFields}
                                    forceSubmit={forceSubmit}
                                    layoutConfig={{ spacing: 3 }}
                                    onChange={debouncedHandleCarrierOnChange}
                                    debouncer={{ wait: 1 }}
                                />
                            </Grid>
                            {selectedCarrier?.name && (
                                <Grid item xs={12}>
                                    <FormSimple
                                        nameID={`${nameID}-form-default fields`}
                                        fields={defaultFields}
                                        forceSubmit={forceSubmit}
                                        layoutConfig={{ spacing: 3 }}
                                        onChange={handleDefaultFieldsChange}
                                        onError={getDefaultFieldsError}
                                        debouncer={{ wait: 1 }}
                                        schemaRefineConfigurations={selectedCarrier?.name ? schemaRefineDefaultFields : undefined}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </AccordionSession>
                </Grid>
                <Grid item xs={12}>
                    {selectedCarrier?.name && (
                        <DoubleAddressForm
                            countries={defaultPickup.countries}
                            title='Address'
                            address1={{
                                data: processAddress(pickupAddress),
                                title: 'Pickup',
                            }}
                            address2={{
                                data: processAddress(shipperAddress),
                                title: 'Shipper',
                            }}
                            getData={getAddressData}
                            getError={getAddressDataError}
                            hasAddressSameAddress2={{ isSame: true, show: false }}
                            disabled={
                                !(isValidDefaultField.isValid &&
                                    isValidCustomSpecifics.isValid &&
                                    isValidHandling.isValid)
                            }
                        />
                    )}
                    {/* isValidHandling: {isValidHandling.isDirty && isValidHandling.isValid ? 'true' : 'false'}
                    <br />
                    isValidAddress: {isValidAddress.isDirty && isValidAddress.isValid ? 'true' : 'false'}
                    <br />
                    isValidDefaultField: {isValidDefaultField.isDirty && isValidDefaultField.isValid ? 'true' : 'false'}
                    <br />
                    isValidCustomSpecifics: {isValidCustomSpecifics.isDirty && isValidCustomSpecifics.isValid ? 'true' : 'false'}
                    <br />
                    (isValidDefaultField.isDirty && isValidHandling.isDirty && isValidCustomSpecifics.isDirty) :{' '}
                    {!isValidDefaultField.isDirty || !isValidHandling.isDirty || !isValidCustomSpecifics.isDirty || !isValidAddress.isDirty
                        ? 'true'
                        : 'false'}
                    <br />
                    !isValidDefaultField.isValid || !isValidAddress.isValid || !isValidCustomSpecifics.isValid || !isValidHandling.isValid :
                    {!isValidDefaultField.isValid || !isValidAddress.isValid || !isValidCustomSpecifics.isValid || !isValidHandling.isValid
                        ? 'false'
                        : 'false'}
                    <br />
                    isValidAddress.isDirty: {isValidAddress.isDirty ? 'true' : 'false'} <br />
                    isValidDefaultField.isDirty: {isValidDefaultField.isDirty ? 'true' : 'false'} <br />
                    isValidCustomSpecifics.isDirty: {isValidCustomSpecifics.isDirty ? 'true' : 'false'} <br />
                    isValidHandling.isDirty: {isValidHandling.isDirty ? 'true' : 'false'} */}
                </Grid>
                <Grid item xs={12} container justifyContent='flex-end'>
                    <Grid item>
                        <PrimaryButton
                            onClick={() => {
                                handleOnSubmit()
                            }}
                            disabled={
                                openDialog ||
                                !isValidAddress.isDirty ||
                                !isValidDefaultField.isDirty ||
                                !isValidCustomSpecifics.isDirty ||
                                !isValidHandling.isDirty
                                    ? true
                                    : !isValidDefaultField.isValid ||
                                    !isValidAddress.isValid ||
                                    !isValidCustomSpecifics.isValid ||
                                    !isValidHandling.isValid
                            }
                        >
                            {t('Schedule Pickup')}
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {defaultPickup?.schedulePickupData && (<Dialog
                size='small'
                open={openDialog}
            >
                <DialogTitle>
                    {
                        defaultPickup.schedulePickupData?.error ?
                        t('Schedule Pickup Failed') : t('Schedule Pickup Succeeded')
                    }
                </DialogTitle>
                <DialogText mt={4} mb={2}>
                    {
                        /* eslint-disable-next-line max-len */
                        defaultPickup.schedulePickupData?.error ?
                            <Grid container>
                                <Grid xs={12}>
                                    {t('There is an error occurred in the process, please contact support for solution.')}
                                </Grid>
                                {defaultPickup.schedulePickupData?.error.message &&
                                    <Grid xs={12}>
                                        <Box mt={4}>
                                            {`${t('Error message')}: ${defaultPickup.schedulePickupData?.error.message}`}
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        :
                        t('Congrats! You have successfully placed your schedule pickup.')
                    }
                </DialogText>
                <DialogButtonContainer centered>
                    <PrimaryButton
                        size='medium'
                        onClick={() => {
                            if (defaultPickup.schedulePickupData?.error) {
                                setOpenDialog(false)
                            } else {
                                history.push('/new/schedule-pickup/')
                            }
                        }}
                    >
                        {t('Close')}
                    </PrimaryButton>
                </DialogButtonContainer>
            </Dialog>)}
        </>
    )
}

export default SchedulePickup
