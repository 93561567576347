import { SearchAddressType } from "@/app/ui-new/components/AddressSearchBar/AddressSearchBar"
import { AddressFormProps } from "@/app/ui-new/components/Form/FullComponents/AddressForm/AddressForm"
import { Carrier } from "@lazr/openapi-client"

export const makeFlattenedArray = (data: any, keys: string[]) =>
  data.map((item: any) => {
    let newItem: any = { ...item } // Create a new object to store the transformed item
    for (const key of keys) {
      if (item[key]) {
        // If 'key' exists, merge its properties with top-level properties
        const { [key]: _, ...rest } = newItem
        newItem = { ...rest, ...item[key] }
      }
    }

    return newItem // Return the transformed item
  })

export const getUrlPath = () => {
  const currentUrl = window.location.href
  const url = new URL(currentUrl)
  const path = url.pathname
  return path
}

export const setHistoryParams = (newParams: string) => {
  const path = getUrlPath()
  history.replaceState({}, '', `${path}?${newParams}`)
  return
}

export const removeParam = (names: string[]) => {
  const newParams = new URLSearchParams(location.search)

  for (const key of names) {
    newParams.delete(key)
  }

  setHistoryParams(`${newParams.toString()}`)
  return 
}


export const processAddress = (data:any) => {
  const dataForm: AddressFormProps['address'] = {
      contactName: { value: '' },
      contactEmails: { value: '' },
      contactPhone: { value: '' },
      contactPhoneExtension: { value: '' },
      addressType: { value: '', hide:true },
      companyName: { value: '' },
      street: { value: '' },
      street3: { value: '' },
      street2: { value: '' },
      city: { value: '' },
      country: { value: '' },
      state: { value: '' },
      postalCode: { value: '' },
  }

  if (dataForm.contactName) {
      dataForm.contactName.value = data?.contactName
  }

  if (dataForm.contactEmails) {
      dataForm.contactEmails.value = data?.contactEmails
  }

  if (dataForm.contactPhone) {
      dataForm.contactPhone.value = data?.contactPhone
  }

  if (dataForm.contactPhoneExtension) {
      dataForm.contactPhoneExtension.value = data?.contactPhoneExtension
  }

  if (dataForm.addressType) {
      dataForm.addressType.value = data?.addressType
  }
  if (dataForm.companyName) {
      dataForm.companyName.value = data?.companyName
  }
  if (dataForm.street) {
      dataForm.street.value = data?.street ?? data?.streetAddress
  }
  if (dataForm.street3) {
      dataForm.street3.value = data?.street3 ?? data?.streetAddress3
  }
  if (dataForm.street2) {
      dataForm.street2.value = data?.street2 ?? data?.streetAddress2
  }
  if (dataForm.city) {
      dataForm.city.value = data?.city
  }
  if (dataForm.state) {
      dataForm.state.value = data?.state
  }
  if (dataForm.country) {
      dataForm.country.value = data?.country
  }
  if (dataForm.postalCode) {
      dataForm.postalCode.value = data?.postalCode
  }

  const addressArr = [data?.streetAddress2, data?.streetAddress1, data?.streetAddress, data?.city, data?.state, data?.country]

  dataForm.googleAddress = {
      description: `${addressArr.filter((item) => !!item).join(', ')} ${data?.postalCode ?? ''}`,
      isAddressBook: false,
      type: SearchAddressType.ADDRESS,
      mainText: `${addressArr.filter((item) => !!item).join(', ')} ${data?.postalCode ?? ''}`,
  }

  return dataForm;
}




export const removeCarrierByName = (data: Carrier[] | undefined  , namesToRemove: string[]): Carrier[]| undefined=>
  data ? data.filter(item => !namesToRemove.includes(item.name)) : undefined;