/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './CreditApplication.i18n.en'
import { Translations } from '@lazr/i18n'

export default ((): Translations<typeof en> => ({
        'Legal name': 'Nom légal',
        'Credit Application': 'Demande de crédit',
        Submit: 'Soumettre',
        'Credit Application Failed' : 'Échec de la demande de crédit',
        'Credit Application Submitted': 'Demande de crédit soumise avec succès',
        'There is an error occurred in the process, please contact support for solution.': 'Une erreur s\'est produite lors du processus, veuillez contacter le support pour une solution.',
        'Congrats! You have successfully submitted your credit application.': 'Félicitations ! Vous avez planifié votre demande de crédit avec succès.',
        'Close': 'Fermer',
})
)()
