/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['System Dashboard']: `System Dashboard`,
    ['Orders']: `Orders`,
    ['Marketplace']: `Marketplace`,
    ['Invoices']: `Invoices`,
    ['Invoice CSV Batches']: `Invoice Batches`,
    ['Address Book']: `Address Book`,
    ['Organizations']: `Organizations`,
    ['My Organization']: `My Organization`,
    ['EDI']: `EDI`,
}
