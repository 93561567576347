import { EventType } from '@lazr/openapi-client'
import { Event } from '@lazr/events'
import { logger } from '../logger'
import * as config from '../config'
import { getToken } from '@/app/service/ApiService'

export const SubscriptionService = Object.freeze({
    subscribe: (events: EventType[], handler: (event: Event) => Promise<void> | void): { destroy: () => void } => {
        let ws: WebSocket | null = null
        let keepAlive: number | null = null
        let reconnect: number | null = null

        const openConnection = () => {
            ws = new WebSocket(config.webSocketApiUrl)

            ws.onopen = () => {
                logger.debug('Opening connection')
                if (ws) {
                    getToken().then((token) => {
                        if (ws) {
                            ws.send(JSON.stringify({ authorization: token, events }))
                        }
                    })
                }
            }

            ws.onmessage = (e: any) => {
                const data = JSON.parse(e.data)

                logger.debug('Received message', data)

                if (data?.status !== 'success') {
                    logger.error('Unexpected message: ', e)
                } else if (data?.event) {
                    void handler(data.event)
                }
            }

            keepAlive = window.setInterval(() => {
                logger.debug('Sending ping request')
                if (ws) {
                    getToken().then((token) => {
                        if (ws) {
                            ws.send(JSON.stringify({ authorization: token }))
                        }
                    })
                }
            }, 60000)

            reconnect = window.setTimeout(() => {
                logger.debug('Reconnecting')
                window.clearInterval(keepAlive as number)
                if (ws) {
                    ws.close()
                }
                openConnection()
            }, 3600000)
        }

        openConnection()

        return Object.freeze({
            destroy: (): void => {
                logger.debug('Closing connection')
                window.clearInterval(keepAlive as number)
                window.clearTimeout(reconnect as number)
                if (ws) {
                    ws.close()
                }
            },
        })
    },
})

export default SubscriptionService
