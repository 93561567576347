import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Helmet } from 'react-helmet'
import { StylesProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import themes from './theme'
import Routes from './routes/Routes'
import 'react-dates/initialize'
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import fr from 'javascript-time-ago/locale/fr'
import { Store } from './definitions/Store'
import { setUser as reduxSetUser,
    setUserIsSignedIn as reduxSetUserIsSignedIn,
    setDefaultUserCurrency as reduxSetDefaultUserCurrency } from './redux/actions/UserActions'
import { setVersion as reduxSetVersion, setVersionSnackbarOpen as reduxSetVersionSnackbarOpen } from './redux/actions/VersionActions'
import {
    setUserOrganization as reduxSetUserOrganization,
} from './redux/actions/UserOrganizationActions'
import reduxSetAppState from './redux/actions/AppStateActions'
import {
    setDefaultBillingAddresses as reduxSetDefaultBillingAddresses,
    setDefaultShippingAddresses as reduxSetDefaultShippingAddresses,
} from './redux/actions/AddressListActions'
import Loader from './pages/components/Loader'
import {
    impersonateRequests,
    localStorageHelper,
    OrganizationApiService,
    UserApiService,
    isSignedIn,
    AddressApiService,
} from '../service/ApiService'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { getUser as reduxGetUser, getUserIsSignedIn as reduxGetUserIsSignedIn } from './redux/selectors/UserSelectors'
import { getVersion as reduxGetVersion } from './redux/selectors/VersionSelectors'
import { GOOGLE_API_URL, SALESFORCE_CRM_SCRIPT_URL } from './redux/constants'
import { logger } from '../logger'
import { Currency, Language as LanguageCode } from '@lazr/openapi-client'
import { User } from '../model'
import { env, Environment } from '../config'
import { VersionApiService } from '../service/ApiService/VersionApiService'
import { setLanguage as reduxSetLanguage } from '@/app/ui/redux/actions/LanguageActions'
import { useDefaultAddressList } from './components/hooks/useDefaultAddressList'
import { useCurrencyExchangeRate } from './components/hooks/useCurrencyExchangeRate'
import { useCurrencyList } from './components/hooks/useCurrencyList'
import { useLanguageList } from './components/hooks/useLanguageList'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './App.i18n'
import { Address, FilteredAddresses } from '../model/Address'
import { orderBillToAddressList } from '@/app/common/helpers'

/* SEO (START) */
//import { Helmet } from 'react-helmet'
/* SEO (END) */

library.add(fab, far, fas, fal, fad)
JavascriptTimeAgo.locale(en)
JavascriptTimeAgo.locale(fr)

const App: React.FunctionComponent<Props> = () => {
    const { t } = useI18n(i18n)
    const isProduction = env === Environment.PRODUCTION
    useI18n(i18n)

    const dispatch = useDispatch()
    const currentUser = useSelector(reduxGetUser)
    const isUserSignedIn = useSelector(reduxGetUserIsSignedIn)
    const version = useSelector(reduxGetVersion)
    useDefaultAddressList(isUserSignedIn)
    useCurrencyExchangeRate(isUserSignedIn)
    useCurrencyList()
    useLanguageList()

    const [ versionInterval, setVersionInterval ] = useState<number | null>(null)

    const { theme, appState } = useSelector((reduxStore: Store) => ({
        theme: reduxStore.theme,
        appState: reduxStore.appState,
    }))

    const usePrevious = <T, >(value: T): T | undefined => {
        const ref = useRef<T>()

        useEffect(() => {
            ref.current = value
        })

        return ref.current
    }

    const prevVersion = usePrevious(version)
    const getAppRootJsx = (children: React.ReactElement): React.ReactElement => (
        <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ i18n.getDateFnsLocale() }>
                <MuiThemeProvider theme={themes[theme]}>
                    <ThemeProvider theme={themes[theme]}>
                        <Helmet>
                            {/* TODO: Remove this load since it's only used by Lazr App. Useless for public website. */}
                            <script src={GOOGLE_API_URL}/>
                            {isProduction && <script src={SALESFORCE_CRM_SCRIPT_URL}/>}
                        </Helmet>
                        {children}
                    </ThemeProvider>
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </StylesProvider>
    )

    useEffect(() => {
        void (async (): Promise<void> => {
            let user: User | undefined
            let apiVersion: string | null = null
            let defaultUserCurrency: Currency = Currency.CAD
            let fetchedBillingAddresses: FilteredAddresses | null = null
            let fetchedDefaultBillingAddresses: FilteredAddresses | null = null
            let fetchedDefaultShippingAddresses: FilteredAddresses | null = null
            if (currentUser && isUserSignedIn && isSignedIn()) {
                return
            }
            try {
                if (isSignedIn()) {
                    user = await UserApiService.getCurrent()
                }
            } catch (error: any) {
                logger.error('could not fetch current user', error)
            }

            if (user) {
                const impersonatedRole = localStorageHelper.getImpersonatedRoleInfo()
                if (impersonatedRole.organizationId && impersonatedRole.organizationName) {
                    impersonateRequests(impersonatedRole.organizationId, impersonatedRole.organizationName, impersonatedRole.isAdmin)
                    user.impersonate({
                        isAdmin: impersonatedRole.isAdmin,
                        organizationId: impersonatedRole.organizationId,
                        organizationName: impersonatedRole.organizationName,
                    })
                }

                let userOrganization
                try {
                    ([
                        userOrganization,
                        apiVersion,
                        fetchedBillingAddresses,
                        fetchedDefaultBillingAddresses,
                        fetchedDefaultShippingAddresses,
                    ] = await Promise.all([
                        OrganizationApiService.getCurrent(),
                        VersionApiService.get(),
                        AddressApiService.list(
                            {
                                page: 1,
                                resultPerPage: 100,
                            },
                            {
                                isBilling: true,
                            },
                        ),
                        await AddressApiService.list(
                            {
                                page: 1,
                                resultPerPage: 100,
                            },
                            {
                                isBilling: true,
                                isBillingDefault: true,
                            },
                        ),
                        await AddressApiService.list(
                            {
                                page: 1,
                                resultPerPage: 100,
                            },
                            {
                                isShipping: true,
                                isShippingDefault: true,
                            },
                        ),
                    ]))
                    if (fetchedBillingAddresses && user) {
                        user.seBillToAddresses(orderBillToAddressList(fetchedBillingAddresses, user).map((address: Address) => ({
                            id: address.id,
                            organizationId: user?.organizationId ?? '',
                            userId: user?.id ?? '',
                            billTo: address,
                        })))
                    }

                    dispatch(reduxSetUser(user))
                    dispatch(reduxSetLanguage(user.language.code))
                    dispatch(reduxSetVersion(apiVersion))
                    dispatch(reduxSetUserOrganization(userOrganization))
                    dispatch(reduxSetUserIsSignedIn(true))
                    dispatch(reduxSetDefaultUserCurrency(defaultUserCurrency))
                    if (fetchedDefaultBillingAddresses) {
                        dispatch(reduxSetDefaultBillingAddresses(fetchedDefaultBillingAddresses.addresses))
                    }
                    if (fetchedDefaultShippingAddresses) {
                        dispatch(reduxSetDefaultShippingAddresses(fetchedDefaultShippingAddresses.addresses))
                    }

                    setVersionInterval(window.setInterval(() => {
                        void (async (): Promise<void> => {
                            dispatch(reduxSetVersion(await VersionApiService.get()))
                        })()
                    }, 5 * 60 * 1000)) // Every 5 minutes (to try to ensure full deploy before prompting the reload)
                } catch (error: any) {
                    logger.error('unable to fetch some or all of "userOrganization"',
                        ', "defaultBillingAddresses".',
                        error,
                    )
                    dispatch(reduxSetUser(user))
                }
            } else {
                let language = LanguageCode.EN

                const s = localStorage.getItem('language')

                if (s === LanguageCode.EN || s === LanguageCode.FR) {
                    language = s
                } else if (window.navigator.language) {
                    const matches = window.navigator.language.match(/^(en|fr)\b/)

                    const found = String(matches?.[0]).toUpperCase()

                    if (found === LanguageCode.EN || found === LanguageCode.FR) {
                        language = found
                    }
                }

                dispatch(reduxSetLanguage(language))
            }

            dispatch(reduxSetAppState({
                isUserFetched: true,
            }))
        })()
    }, [ isUserSignedIn ])

    useEffect(() => {
        if (!prevVersion || prevVersion === version) {
            return
        }

        if (versionInterval !== null) {
            window.clearInterval(versionInterval)
            setVersionInterval(null)
        }

        dispatch(reduxSetVersionSnackbarOpen(true))

        return () => {
            if (versionInterval !== null) {
                window.clearInterval(versionInterval)
                setVersionInterval(null)
            }
        }
    }, [ version ])

    useEffect((): () => void => {
        const callback = (event: StorageEvent) => {
            if (event.key === 'lazrCognitoToken' && event.oldValue && !event.newValue) {
                window.location.reload()
            }
        }

        window.addEventListener('storage', callback)

        return () => window.removeEventListener('storage', callback)
    }, [])

    if (!appState.isUserFetched) {
        return getAppRootJsx(<Loader/>)
    }

    return getAppRootJsx(<Routes/>)
}

export interface Props {}
export default withRouter(App)
