import React from 'react'
import Header from '@/app/ui-new/components/Header/Header'
import { useI18n } from '@/app/ui/components/hooks/I18n'
import i18n from './View.i18n'
import CreditApplication, { CreditApplicationProps } from '../sharedModules/CreditApplication.module/CreditApplication'
import { GridView } from '../../schedule-pickup/New/View.styled'
import { Box } from '@material-ui/core'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

export interface Props {
    nameID?: string
    defaultCreditApplication: CreditApplicationProps['defaultCreditApplication']
}
const View: React.FunctionComponent<Props> = ({  nameID, defaultCreditApplication }: Props) => {
    const { t } = useI18n(i18n)
    const history = useHistory()
    return (
        <>
            <GridView id={nameID}>
                <Header
                    title={{
                        value: t('Create Credit Application'),
                    }}
                    layoutConfig={{
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                    nameID={`${nameID}-header`}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <SecondaryButton
                            onClick={() => history.goBack()}
                            size='small'
                            startIcon={<FontAwesomeIcon icon={['fas', 'arrow-left']} />}
                            style={{ maxWidth: '200px', marginRight: '10px' }}
                            id={'listPickup'}
                        >
                            {t('Go Back')}
                        </SecondaryButton>
                    </Box>
                </Header>
                <br />
                <CreditApplication nameID={nameID ?? ''} defaultCreditApplication={defaultCreditApplication} />
            </GridView>
        </>
    )
}

export default View
